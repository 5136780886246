import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FaEye, FaPlus } from 'react-icons/fa'
import { FiTrash2 } from 'react-icons/fi'
import {
    PageTitle,
    DataTable,
    ErrorCard,
    ActionTooltip,
    FormInfo
} from '../../../components/'
import { connect } from 'react-redux'
import {
    Button, CardHeader,
    Row, Col,
    Card, CardBody,
    CardFooter, Container,
} from 'reactstrap';
import { getCompaniesAction } from '../../../actions/companyAction'
import {
    getEmployeesAction,
    deleteEmployeeAction,
    getEmployeeDocumentsAction,
    editEmployeeAction
} from '../../../actions/employeesAction'
import { getDocumentsAction, getTypesAction, getDocumentModelsAction } from '../../../actions/documentAction'
import { schema } from '../../../utils/forms/employee'
import { companyImg } from '../../../assets/images'

const RapidAction = ({ company, employee, handleDelete }) => (
    <Col md="4" className="pr-5 d-flex flex-column justify-content-start align-items-end">
        <h4 className="mt-2 mb-3 mr-2 text-light">
            Azioni rapide
                                        </h4>
        <Link to={{ pathname: '/document/add/', state: { companyId: company.id, employeeId: employee.id } }}>
            <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
                <div className="mr-3">
                    <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
                </div>
                <div className="text-black">
                    Assegna una scadenza
                </div>
            </Button>
        </Link>
        <Link to={`/company/edit/${employee.company_id}/`}>
            <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-4 d-flex text-left align-items-center">
                <div className="mr-3">
                    <FaEye className="hoverable-icon static brand p-2"></FaEye>
                </div>
                <div className="text-black">
                    Vai all'azienda
                </div>
            </Button>
        </Link>
        {handleDelete &&
            <ActionTooltip
                id={`item-delete`}
                handleDelete={() => handleDelete(employee.id)}
                item={{ placement: "bottom" }}
                text={"Sei sicuro di voler eliminare questo oggetto?"}
                color="white"
            />
        }
        <Button id={`Tooltip-item-delete`} className="new-expiration-button py-1 pl-1 pr-3 shadow mb-3 d-flex text-left align-items-center">
            <div className="mr-3">
                <FiTrash2 className="hoverable-icon static danger p-2"></FiTrash2>
            </div>
            <div className="text-danger">
                Cancella il dipendente
            </div>
        </Button>
    </Col>
)

class EmployeeEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employee: {
                id: 0,
                name: "",
                surname: "",
                email: "",
                telephone_number: "",
                companyId: 0,
                companyName: "",
                createdAt: "",
            },
            companies: []
        }
    }

    get employeeDocuments() {
        const allDocuments = Object.values(this.props.documents.byId)
        const employeeId = this.props.match.params.id
        const employee = this.props.employees.byId[employeeId];
        console.log(employee)
        if (!employee) return []

        if (employee.documents) {
            return allDocuments.filter((document) => employee.documents.includes(document.id))
        }
        return []
    }


    async componentDidMount() {
        if (!this.props.match.params.id) {
            return this.props.history.push('/employee/')
        }

        await this.props.getCompanies()
        await this.props.getDocumentModels();
        await this.props.getTypes()

        const employeeId = this.props.match.params.id;
        if (!this.props.employees.byId[employeeId]) {
            await this.props.getEmployees(employeeId)
            if (!this.props.employees.byId[employeeId])
                return this.props.history.push('/employee/')
        }

        const employee = this.props.employees.byId[employeeId]
        this.setState({ employee }, () => console.log('employee/edit: updating state', this.state))


        await this.props.getDocuments()

    }

    renderCell = (field, value) => {
        switch (field) {
            case "id":
                return (
                    <td className="text-center text-muted p-3" name={field}>
                        {value}
                    </td>
                );
            case "documentsId":
                return (
                    <td className="text-center p-3">
                        <div className={"badge badge-pill badge-info"}>{value.length}</div>
                    </td>
                );
            case "documentmodel_id":
                console.log(this.props.documentModels.byId[value])
                return (
                    <td className="text-center p-3" name={field}>

                        {this.props.documentModels.byId[value] && this.props.documentModels.byId[value].name}
                    </td>
                )
            default:
                return (
                    <td className="text-center p-3" name={field}>
                        {value}
                    </td>
                );
        }
    };

    handleDelete = async id => {
        await this.props.deleteEmployee(id)
        this.props.history.push('/employee/')
    }

    handleDeleteDocument = documentId => {
        this.props.deleteDocument(documentId)
    }

    handleChange = evt => {
        this.setState(prevState => ({
            ...prevState,
            employee: {
                ...prevState.employee,
                ...evt.formData,
            }
        }), () => console.log("employee/edit: updating state", this.state))
    }

    handleSubmit = () => {
        this.props.editEmployee(this.state.employee)
    }

    // handleSelect = (evt, metadata) => {
    //     const valueForSelect = evt;
    //     const value = evt.value;
    //     const name = metadata.name;
    //     console.log(valueForSelect, value, name)
    //     this.setState(prevState => ({
    //         ...prevState,
    //         [name]: value,
    //         select: {
    //             ...prevState.select,
    //             [name]: valueForSelect
    //         }
    //     }), () => console.log("company/edit: updating state", this.state))
    // }


    render() {

        const { employees, companies, match: { params: { id } }, edited, pending } = this.props;
        const employee = employees.byId[id];

        return (
            <Container fluid>
                {employee && companies.byId[employee.company_id] && <PageTitle
                    heading={`${employee.first_name} ${employee.last_name}`}
                    subheading={`Inserito il ${employee.created_at} in ${companies.byId[employee.company_id].business_name}`}
                    icon={false}
                    color={true}

                >
                    <RapidAction
                        handleDelete={this.handleDelete}
                        employee={employee}
                        company={companies.byId[employee.company_id]}
                    />
                </PageTitle>}
                <Row>
                    <Col md="6">
                        <h2 className="mb-3">Anagrafica</h2>
                        <FormInfo
                            schema={schema}
                            formData={this.state.employee}
                            pending={pending}
                            onChange={e => this.handleChange(e)}
                            onSubmit={() => this.handleSubmit()}
                            onError={e => console.log(e)}
                            isChildrenInside={true}
                            messages={edited.status && {
                                status: edited.status,
                                message: edited.message
                            }}
                        >   <></>
                            {/* <FormGroup>
                                <Label>
                                    Settore
                                </Label>
                                <Select
                                    isLoading={this.state.sectors.length === 0}
                                    name="sector_id"
                                    options={this.state.sectors}
                                    onChange={this.handleSelect}
                                    value={this.state.select.sector_id}
                                />
                            </FormGroup> */}
                        </FormInfo>
                    </Col>
                    <Col md="6 smaller-img">

                        {
                            employee && companies.byId[employee.company_id] &&
                            <>
                                <h2 className="mb-3">Azienda</h2>
                                <ErrorCard
                                    img={companyImg}
                                    customText={`L'azienda a cui è assegnato il dipendente è ${companies.byId[employee.company_id].business_name}`}
                                    translation={null}
                                    action={{ to: `/company/edit/${employee.company_id}`, cta: "Vai all'azienda" }}
                                    bread={'Dipendenti'}
                                />
                            </>
                        }

                    </Col>
                </Row>
                <Row>
                    <Col md="12 mb-5">
                        <h2 className="mb-3">Documenti</h2>
                        {this.employeeDocuments.length > 0 ?
                            (
                                <>
                                    <DataTable
                                        title="Cerca:"
                                        handleDelete={this.handleDeleteDocument}
                                        columns={[
                                            {
                                                Header: 'Id',
                                                accessor: 'id'
                                            },
                                            {
                                                Header: 'Nome',
                                                accessor: 'documentmodel_id'
                                            },
                                            {
                                                Header: 'Scadenza',
                                                accessor: 'expiration_date'
                                            },
                                            {
                                                Header: 'Tipo',
                                                accessor: 'type'
                                            },
                                            {
                                                Header: 'Assegnato a',
                                                accessor: row => (
                                                    employees.byId[row.employee_id] &&
                                                    `${employees.byId[row.employee_id].first_name} ${employees.byId[row.employee_id].last_name}` ||
                                                    "Nessun dipendente"
                                                )
                                            }
                                        ]}
                                        data={this.employeeDocuments}
                                        model={"documents"}
                                        renderCell={this.renderCell}
                                    />
                                </>
                            ) :
                            <ErrorCard
                                img={false}
                                model={employee}
                                translation={'documenti'}
                                action={{ to: '/document/add', cta: 'Aggiungi un documento' }}
                            />
                        }
                    </Col>
                </Row>
            </Container>
        )
    }
}


const mapDispatchToProps = dispatch => ({
    getCompanies: () => dispatch(getCompaniesAction()),
    getEmployees: () => dispatch(getEmployeesAction()),
    getDocuments: () => dispatch(getDocumentsAction()),
    getEmployeeDocuments: employeeId => dispatch(getEmployeeDocumentsAction(employeeId)),
    deleteEmployee: employeeId => dispatch(deleteEmployeeAction(employeeId)),
    getDocumentModels: () => dispatch(getDocumentModelsAction()),
    editEmployee: employee => dispatch(editEmployeeAction(employee)),
    getTypes: () => dispatch(getTypesAction()),
})

const mapStateToProps = state => {
    const employees = state.employees
    const companies = state.companies
    const documents = state.documents.documents
    const documentModels = state.documents.documentModels
    const types = state.documents.types
    const edited = state.employees.edited
    const pending = state.employees.pending

    return { employees, companies, documents, documentModels, types, edited, pending }
}


export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEdit)
