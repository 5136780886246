import React from 'react';
import { Button, Tooltip } from 'reactstrap';

class ViewTooltip extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        return (
            <span>

                <Tooltip
                    className="p-3 bg-white"
                    placement={this.props.item.placement}
                    isOpen={this.state.tooltipOpen}
                    target={'Tooltip-' + this.props.id}
                    trigger="focus"
                    toggle={this.toggle}
                >
                    <div className="m-2 d-flex flex-row justify-content-around">
                        {this.props.children}
                    </div>
                </Tooltip>
            </span>
        );
    }
}

export default ViewTooltip;
