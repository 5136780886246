import API from '../config/webservice';


/**
 * auth decorator ^_^
 * 
 * @param {*} fn - the real function
 * @param {*} ctx - the this, context
 * @returns {Function}
 */
function auth(fn, ctx) {
    return function () {
        if (this.authToken === '') {
            return Promise.resolve({ error: true, status: 401, message: 'Unauthorized' });
        }
        return fn.apply(this, arguments);
    }.bind(ctx);
}


export class Api {
    /**
     * Creates an instance of Api.
     * @memberof Api
     */
    constructor() {
        this.authToken = '';
        // this.fetchUserInfo = auth(this.fetchUserInfo, this);

    }

    /**
     *
     *
     * @param {*} authToken
     * @memberof Api
     */
    setAuthToken(authToken) {
        this.authToken = authToken;
    }

    /**
     *
     *
     * @param {*} { user, password }
     * @returns
     * @memberof Api
     */
    async login(user) {
        try {
            const response = await API.post('api/token/', {
                ...user
            })
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }


    }

    async getDocumentTypes() {
        try {
            const response = await API.get('/documenttype/')
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async addDocumentModel(documentModel) {
        try {
            const response = await API.post('/documentmodels/', {
                ...documentModel
            })
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async addDocument(document) {
        try {
            const response = await API.post('/documents/', {
                ...document
            })
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async editDocument(document) {
        try {
            const response = await API.patch(`/documents/${document.id}/`, {
                ...document
            })
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async editEmployee(employee) {
        try {
            const response = await API.patch(`/employees/${employee.id}/`, {
                ...employee
            })
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async editDocumentModel(documentModel) {
        try {
            const response = await API.patch(`/documentmodels/${documentModel.id}/`, {
                ...documentModel
            })
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async addEmployee(employee) {
        try {
            const response = await API.post('/employees/', {
                ...employee
            })
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async addCompany(company) {
        try {
            const response = await API.post('/companies/', {
                ...company
            })
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async editCompany(company) {
        try {
            const response = await API.patch(`/companies/${company.id}/`, {
                ...company
            })
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async getDocumentModels(id = '') {
        try {
            const response = await API.get(`/documentmodels/${id}`)
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async getDocuments(id = '') {
        try {
            const response = await API.get(`/documents/${id}`)
            console.log(response)
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async getCompanies(id = '') {
        try {
            const response = await API.get(`/companies/${id}`)
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async deleteCompany(id) {
        try {
            const response = await API.delete(`/companies/${id}`)
            console.log(response)
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async deleteEmployee(id) {
        try {
            const response = await API.delete(`/employees/${id}`)
            console.log(response)
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async deleteDocument(id) {
        try {
            const response = await API.delete(`/documents/${id}`)
            console.log(response)
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async deleteDocumentModel(id) {
        try {
            const response = await API.delete(`/documentmodels/${id}`)
            console.log(response)
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async getEmployees(id = '') {
        try {
            const response = await API.get(`/employees/${id}`)
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async getSectors(id = '') {
        try {
            const response = await API.get(`/sectors/`)
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async getCompanyEmployees(companyId) {
        try {
            const response = await API.get(`/companies/${companyId}/employees/`)
            return response;
        } catch (error) {
            if (error.response) {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async getCompanyDocuments(companyId) {
        try {
            const response = await API.get(`/companies/${companyId}/documents/`)
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }

    async getEmployeeDocuments(employeeId) {
        try {
            const response = await API.get(`/employees/${employeeId}/documents/`)
            return response;
        } catch (error) {
            if (error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, qualcosa è andato storto!"
                }
                return errorObj
            } else if (!!error.isAxiosError && !error.response) {

                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Abbiamo qualche problemino di connessione. Non preoccuparti, siamo già all’opera per risolverlo!"
                }
                return errorObj
            } else {
                const errorObj = {
                    serialized: JSON.stringify(error),
                    message: "Ops, si è verificato un errore!"
                }
                return errorObj
            }
        }
    }


    // /**
    //  *
    //  *
    //  * @param {*} { userId }
    //  * @returns
    //  * @memberof Api
    //  */

    // fetchUserInfo({ userId }) {
    //     return fetch(`${config.apiUri}api/v1/me`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'X-Auth-Token': this.authToken,
    //             'X-User-Id': userId
    //         }
    //     })
    //         .then(parse);
    // }
    // /**
    //  *
    //  *
    //  * @param {*} { userId }
    //  * @returns
    //  * @memberof Api
    //  */
    // fetchRooms({ userId }) {
    //     return fetch(`${config.apiUri}api/v1/users.list`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'X-Auth-Token': this.authToken,
    //             'X-User-Id': userId
    //         }
    //     }).then(parse);
    // }

    // /**
    //  *
    //  *
    //  * @param {*} { userId, activeRoom, message }
    //  * @returns
    //  * @memberof Api
    //  */
    // sendMessage({ userId, activeRoom, message }) {
    //     return fetch(`${config.apiUri}api/v1/chat.postMessage`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'X-Auth-Token': this.authToken,
    //             'X-User-Id': userId
    //         },
    //         body: JSON.stringify({
    //             roomId: activeRoom,
    //             text: message
    //         })
    //     }).then(parse)
    // }

    // /**
    //  *
    //  *
    //  * @param {*} { username, userId }
    //  * @returns
    //  * @memberof Api
    //  */
    // fetchRoomMessages({ username, userId }) {
    //     return fetch(`${config.apiUri}api/v1/im.messages?username=${username}`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'X-Auth-Token': this.authToken,
    //             'X-User-Id': userId
    //         }
    //     })
    //         .then(parse)
    // };

    // /**
    //  *
    //  *
    //  * @param {*} { username, userId }
    //  * @returns
    //  * @memberof Api
    //  */
    // createChatWith({ username, userId }) {
    //     return fetch(`${config.apiUri}api/v1/im.create`, {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'X-Auth-Token': this.authToken,
    //             'X-User-Id': userId
    //         },
    //         body: JSON.stringify({
    //             username: username
    //         })
    //     }).then(parse);
    // }
}

export default new Api()
