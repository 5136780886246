const labelMap = {
    "certificato": "danger",
    "visita": "info",
    "corso": "warning"
}

const schema = {
    title: "",
    type: "object",
    required: ['shorthand', 'business_name', 'address', 'referrer_id'],
    properties: {
        id: { type: "number", title: "Id", default: "", readOnly: true },
        shorthand: { type: "string", title: "Nome", },
        business_name: { type: "string", title: "Ragione Sociale", },
        email: { type: "string", title: "Email", },
        telephone_number: { type: "string", title: "Telefono" },
        // sector_id: {
        //     type: "string",
        //     title: "Settore",
        //     enum: [1, 2, 3, 4, 5, 6, 7, 8],
        //     enumNames: ["Altro", "Tessile", "Plastica", "Ristorazione", "Immobiliare", "Servizi", "Impiantistica", "Agricoltura"]
        // },
        vat_number: { type: "string", title: "Partita Iva", },
        address: { type: "string", title: "Indirizzo", },
        referrer_id: { type: "string", title: "Referente" },
        created_at: { type: "string", title: "Creato il", readOnly: true },
    }
};

export { labelMap, schema };