// export const certificate = require('certification.svg');
import certificate from './certification.svg'
import hiring from './hiring.svg'
import medicine from './medicine.svg';
import portfolio from './portfolio.svg';
import security from './security.svg';
import resume from './resume.svg';
import specs from './specs.svg';
import surveillance from './surveillance.svg';
import course from './course.svg'
import errorImg from './error.svg'
import companyImg from './company.svg'
import confirmImg from './confirm.svg'

export const typeImages = {
    "certificate": {
        "image": hiring,
        "_it": "Certificazioni"
    },
    "medicine": {
        "image": medicine,
        "_it": "Sorv. Sanitaria"
    },
    "security": {
        "image": security,
        "_it": "Sicurezza"
    },
    "course": {
        "image": course,
        "_it": "Formazione"
    },
    "maintenance": {
        "image": specs,
        "_it": "Manutenzione"
    },
    "alternative": {
        "image": resume,
        "_it": "Altro"
    }
}


export { errorImg, companyImg, confirmImg }

