import Api from '../api/api'
import { createAction } from '@reduxjs/toolkit'

export const loginUserAction = user => {
    return async dispatch => {
        dispatch(loginUserPending())
        const response = await Api.login(user)
        if (response.message) {
            dispatch(loginUserError(response))
        }
        else {
            const data = response.data;
            localStorage.setItem("token", data.jwt)
            dispatch(loginUserSuccess(data.user))
        }
    }
}

export const loginUserPending = createAction("LOGIN_USER_PENDING")
export const loginUserSuccess = createAction("LOGIN_USER_SUCCESS")
export const loginUserError = createAction("LOGIN_USER_ERROR")
export const logoutUser = createAction("LOGOUT_USER")
