import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Row, Col, Button, Jumbotron } from 'reactstrap';
import { CountupWrapper } from '../../components/';
import { getCompaniesAction } from '../../actions/companyAction'
import {
    getEmployeesAction,
    deleteEmployeeAction,
    getEmployeeDocumentsAction
} from '../../actions/employeesAction'
import { getDocumentsAction, getTypesAction, getDocumentModelsAction } from '../../actions/documentAction'
import { TiTimesOutline } from 'react-icons/ti'
import cx from 'classnames'

class Home extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    getExpirationByTime = timespan => {
        const allDocuments = Object.values(this.props.documents.byId)
        if (!allDocuments.length > 0) {
            return []
        }
        const today = new Date()
        const tmpDate = new Date()
        const timespanDate = new Date(tmpDate.setDate(tmpDate.getDate() + timespan))

        if (timespan === 0) {
            return allDocuments.filter(document => {
                const expirationDate = new Date(document.expiration_date)
                return today > expirationDate
            })
        }

        if (!timespan) {
            return allDocuments
        }


        return allDocuments.filter(document => {
            const expirationDate = new Date(document.expiration_date)
            return today < expirationDate && expirationDate < timespanDate
        })
    }

    async componentDidMount() {

        await this.props.getCompanies()
        await this.props.getDocumentModels();
        await this.props.getTypes()
        await this.props.getDocuments()

    }

    render() {

        const { documentModels, companies, user, documents } = this.props
        const filters = [7, 30, 60];

        return (
            <>
                <Row className="mt-md-5">
                    {/* <Col sm="12" md="12" xl="12">
                        <div className="card mb-3 px-5 py-5 widget-chart">
                            <Row>
                                <h1 className="">Bentornato, {user.username}</h1>
                            </Row>
                        </div>
                    </Col> */}

                    <Col sm="12" md="12" xl="12" className="mt-md-5 mt-xs-3">
                        {documents.error &&
                            <Link to="/" className="unset-underline" onClick={() => window.location.reload()}>
                                <div className="card mb-3 px-4 py-4 refresh-error">
                                    <div className="icon">
                                        <TiTimesOutline />
                                    </div>
                                    <div className="text">
                                        <h3 className="mb-0">Stiamo avendo qualche problema di connessione!</h3>
                                        <h5 className="mb-0">Clicca qui per ricaricare la pagina</h5>
                                    </div>

                                </div>
                            </Link>}

                        <Jumbotron className="home-info mt-5">
                            <Row className="mt-md-5">
                                <Col md="7">
                                    <h1 className="display-4  ml-5">Ciao, {user.first_name} {user.last_name}!</h1>
                                    <p className="lead ml-5">
                                        Ci sono {this.getExpirationByTime(1).length} nuove scadenze oggi!
                                </p>
                                    <p className="lead second ml-5">
                                        Rimangono {this.getExpirationByTime(0).length} documenti scaduti che richiedono la tua attenzione.
                                </p>
                                </Col>
                                <Col md="5">
                                    <img className="image hide-on-half" src={require('../../assets/images/jumbo-2.svg')}></img>
                                </Col>
                            </Row>
                        </Jumbotron>
                    </Col>
                </Row>

                <Row>
                    {filters.map(filter => (
                        <Col sm="12" md="6" xl="4">
                            <Link className="unset-underline" to={{ pathname: '/document/', state: { filter } }}>
                                <div className="card mb-3 widget-chart text-md-left">
                                    <div className="widget-chart-content ml-3">
                                        <div className="icon-wrapper  ml-0 rounded">
                                            <div className={cx("icon-wrapper-bg ", {
                                                "bg-danger": filter === 7,
                                                "bg-warning": filter === 30,
                                                "bg-info": filter === 60
                                            })} />
                                            <i className={cx({
                                                "text-danger": filter === 7,
                                                "text-warning": filter === 30,
                                                "text-info": filter === 60
                                            })} style={{ fontStyle: 'normal' }}>{filter}g</i>
                                        </div>
                                        <div className="widget-numbers d-flex flex-row align-items-end mb-0">
                                            <h4 className="mr-2 mb-1 ">Hai</h4>
                                            <CountupWrapper
                                                start={0}
                                                end={this.getExpirationByTime(filter).length}
                                            />
                                            <h4 className="ml-2 mb-1 ">scadenze</h4>
                                        </div>
                                        <div
                                            className={cx("widget-subheading fsize-3 pt-0 opacity-10 font-weight-bold", {
                                                "text-danger": filter === 7,
                                                "text-warning": filter === 30,
                                                "text-info": filter === 60
                                            })}>
                                            nei prossimi {filter} giorni
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    ))}

                    {/* <Col sm="12" md="6" xl="4">
                        <Link to={{ pathname: '/document/', state: { filter: 30 } }}>
                            <div className="card mb-3 widget-chart  text-md-left">
                                <div className="widget-chart-content ml-3">
                                    <div className="icon-wrapper ml-0 rounded">
                                        <div className="icon-wrapper-bg bg-warning" />
                                        <i className="text-warning" style={{ fontStyle: 'normal' }}>30g</i>
                                    </div>
                                    <div className="widget-numbers ">
                                        <CountupWrapper
                                            start={0}
                                            end={this.getExpirationByTime(30).length}
                                        />
                                    </div>
                                    <div
                                        className="widget-subheading fsize-1 pt-2 opacity-10 text-warning font-weight-bold">
                                        scadenze nei prossimi 30 giorni
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col sm="12" md="12" xl="4">
                        <Link to={{ pathname: '/document/', state: { filter: 30 } }}>
                            <div className="card mb-3 widget-chart  text-md-left">
                                <div className="widget-chart-content ml-3 ">
                                    <div className="icon-wrapper ml-0 rounded">
                                        <div className="icon-wrapper-bg bg-info" />
                                        <i className="text-info" style={{ fontStyle: 'normal' }} >60g</i>
                                    </div>
                                    <div className="widget-numbers">
                                        <CountupWrapper
                                            start={0}
                                            end={this.getExpirationByTime(60).length}
                                        />
                                    </div>
                                    <div
                                        className="widget-subheading fsize-1 pt-2 opacity-10 text-info font-weight-bold">
                                        scadenze nei prossimi 60 giorni
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </Col> */}
                </Row>
                <Row>
                    <Col sm="12" md="12" xl="12">
                        <div className="card mb-3 widget-chart text-md-left">

                            <Row>
                                <Col md="3" className="p-4">
                                    <div className="widget-chart-content ml-3">
                                        <h3>Documenti scaduti</h3>
                                        <div
                                            className="widget-subheading fsize-1 pt-2 opacity-10 text-danger font-weight-bold">
                                            aggiornato ad oggi
                                        </div>
                                        <div className="widget-numbers bigger">
                                            <CountupWrapper
                                                start={0}
                                                end={this.getExpirationByTime(0).length}
                                            />
                                        </div>

                                        <div className="widget-description show-more-box opacity-7">
                                            <span>Sono mostrati i primi 12 elementi,</span>
                                            <Link to={{ pathname: '/document/', state: { filter: 0 } }}>
                                                <Button color="primary" className="btn-pill btn-shadow btn-wide fsize-1 show-more mx-1" size="sm">
                                                    <span className="font-weight-normal">
                                                        clicca qui
                                                    </span>
                                                </Button>
                                                <span>per vederli tutti</span>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                                <Col md="9" className="d-flex flex-wrap justify-content-center quick-exp-recap py-3">

                                    {this.getExpirationByTime(0).slice(0, 12).map(document => {
                                        const parsedDate = document.expiration_date.slice(5).split('-')
                                        return (
                                            <Link to={`/document/edit/${document.id}`}>
                                                <div className="d-flex flex-row flex-shrink m-1 p-1 expiration">
                                                    <div className=" d-flex flex-column justify-content-center first mr-2">
                                                        <span className="opacity-8">Scaduto</span>
                                                        <span className="font-weight-bold exp-date">{`${parsedDate[1]}/${parsedDate[0]}`}</span>
                                                    </div>
                                                    <div className=" d-flex flex-column second my-1">
                                                        <span className="doc-name"> {documentModels[document.documentmodel_id].name}</span>
                                                        <span className="comp-name opacity-8">{companies[document.company_id].business_name}</span>
                                                    </div>
                                                    <div className="third mr-1">
                                                    </div>
                                                </div>
                                            </Link>

                                        )
                                    })}

                                </Col>
                            </Row>


                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getCompanies: () => dispatch(getCompaniesAction()),
    getEmployees: () => dispatch(getEmployeesAction()),
    getDocuments: () => dispatch(getDocumentsAction()),
    getEmployeeDocuments: employeeId => dispatch(getEmployeeDocumentsAction(employeeId)),
    deleteEmployee: employeeId => dispatch(deleteEmployeeAction(employeeId)),
    getDocumentModels: () => dispatch(getDocumentModelsAction()),
    getTypes: () => dispatch(getTypesAction()),
})

const mapStateToProps = state => {
    const employees = state.employees
    const companies = state.companies.byId
    const documents = state.documents.documents
    const documentModels = state.documents.documentModels.byId
    const types = state.documents.types
    const user = state.user.user
    return { employees, companies, documents, documentModels, types, user }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))