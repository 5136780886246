import React, { Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router';
import AppHeader from '../../layout/AppHeader/';
import AppSidebar from '../../layout/AppSidebar/';
import ThemeOptions from '../../layout/ThemeOptions/';
// import Company from './view/Company'
import EmployeeList from './list/';
import EmployeeEdit from './edit/';
import EmployeeAdd from './add/';
import NotFound from '../../pages/NotFound'


class Employee extends React.Component {
    render() {
        return (
            <Switch>
                <Route path="/employee" exact component={EmployeeList} />
                <Route path="/employee/edit/:id" exact component={EmployeeEdit} />
                <Route path="/employee/add" exact component={EmployeeAdd} />
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>


        );
    }
}

export default Employee;
