import { normalize, schema } from 'normalizr'

export const type = new schema.Entity('types')

export const employee = new schema.Entity('employees', {
    documents: [document]
})

export const documentModel = new schema.Entity('documentModels', {
    type: type
})

export const document = new schema.Entity('documents', {
    employee: employee,
    documentModel: documentModel,
    company: company
})

export const company = new schema.Entity('companies', {
    documents: [document],
    employees: [employee]
})

