import React from 'react'
import CountUp from 'react-countup';

const CountupWrapper = ({ start, end }) => {
    return (
        <CountUp start={start}
            end={end}
            separator=""
            decimals={0}
            decimal="."
            prefix=""
            useEasing={false}
            suffix=""
            duration="3" />
    )
}

export default CountupWrapper