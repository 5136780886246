import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router';
import AppHeader from '../../layout/AppHeader/';
import AppSidebar from '../../layout/AppSidebar/';
import ThemeOptions from '../../layout/ThemeOptions/';
// import Company from './view/Company'
// import Document from './list/';
import DocumentModelEdit from './edit/';
import DocumentModelAdd from './add/';
import DocumentModelList from './list/'
import NotFound from '../../pages/NotFound'


class DocumentModel extends React.Component {
    render() {
        return (
            <Switch>
                <Route path="/documentModel/edit/:id" exact component={DocumentModelEdit} />
                <Route path="/documentModel/add/" exact component={DocumentModelAdd} />
                <Route path="/documentModel" exact component={DocumentModelList} />
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        );
    }
}

export default withRouter(connect()(DocumentModel));
