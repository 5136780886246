import React from 'react';
import {
    Button, CardHeader,
    CardBody, CardFooter,
    Card, Label,
    Col, FormGroup,
    Row, Tooltip
} from "reactstrap";
import DatePicker from "react-datepicker";
import cx from 'classnames';
import Api from '../../api/api'

class TooltipItem extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
            inEditing: {
                start_date: new Date(),
                expiration_date: new Date(),
            },
            expiration: 0,
            error: null
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

    }


    handleClick() {
        if (!this.state.tooltipOpen) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            tooltipOpen: !prevState.tooltipOpen,
        }));
    }

    handleChangeStartDate = async date => {
        console.log(date)
        this.setState(prevState => ({
            ...prevState,
            inEditing: {
                documentId: this.props.object.id,
                ...prevState.inEditing,
                start_date: new Date(date)
            }

        }), () => console.log(this.state, this.props.object))


        if (!this.props.object.documentmodel_id) {
            return
        }

        let expiration = this.state.expiration;
        if (!this.state.expiration) {
            const documentModels = await Api.getDocumentModels(this.props.object.documentmodel_id)
            if (documentModels.message) {
                const message = "Non siamo riusciti a recuperare il modello, inserisci manualmente una data di scadenza!"
                return this.setState({ error: message })
            }
            const model = documentModels.data
            expiration = model.expiration
            this.setState({ expiration: model.expiration })
        }

        const newEndDate = new Date(date)
        const endDate = new Date(newEndDate.setMonth(newEndDate.getMonth() + expiration))
        this.handleChangeEndDate(endDate)

    }

    handleChangeEndDate = date => {
        console.log(date)

        this.setState(prevState => ({
            ...prevState,
            inEditing: {
                ...prevState.inEditing,
                expiration_date: date
            }
        }), () => console.log(this.state))
    }

    handleOutsideClick(e) {
        // ignore clicks on the component itself
        if (this.node.contains(e.target)) {
            return;
        }

        this.handleClick();
    }

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    componentDidMount() {
        if (!this.props.dates) {
            return
        }
        const { dates } = this.props
        console.log(dates)
        this.handleChangeStartDate(dates.end)
    }


    render() {

        const { handleAction, actionText, item, id, color, buttonColor, forceToggle, renew } = this.props
        const { inEditing } = this.state
        return (
            <span ref={node => { this.node = node; }}>
                <Tooltip
                    className={cx("p-3", {
                        "bg-brand": color === "brand",
                        "bg-white": color === "white"
                    })}
                    onClick={forceToggle ? undefined : this.handleClick}
                    placement={item.placement}
                    isOpen={this.state.tooltipOpen}
                    target={'Tooltip-' + id}
                    trigger="click"
                    toggle={this.toggle}
                    autohide={!forceToggle}
                >

                    <div className="m-2">
                        <h5 className={cx("font-weight-bold", {
                            "text-light": !color || color === "brand",
                            "text-dark": color === "white"
                        })}>{this.props.title}</h5>
                        {this.props.text}
                        {this.props.children}
                        {renew && (
                            <Row>
                                {this.state.error && <h6 className="text-light p-2">{this.state.error}</h6>}
                                <Col>
                                    <FormGroup>
                                        <Label className="subheading">Inizio</Label>
                                        <DatePicker
                                            dateFormat='yyyy-MM-dd'
                                            selected={inEditing.start_date}
                                            onChange={this.handleChangeStartDate}
                                            className="form-control bigger"
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label className="subheading">Fine</Label>
                                        <DatePicker
                                            dateFormat='yyyy-MM-dd'
                                            selected={inEditing.expiration_date}
                                            onChange={this.handleChangeEndDate}
                                            className="form-control bigger"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        )}
                    </div>
                    <div className="m-2 d-flex flex-row justify-content-around">
                        <Button color={buttonColor} onClick={() => handleAction(this.state.inEditing)}>{actionText}</Button>
                        <Button onClick={forceToggle ? () => this.toggle() : undefined}>Annulla</Button>
                    </div>
                </Tooltip>
            </span >
        );
    }
}

export default TooltipItem;
