import { createReducer } from '@reduxjs/toolkit'
import { merge } from 'lodash'

const initialState = {
    types: {
        byId: {},
        allIds: [],
        editing: {},
        created: {
            status: false,
            document: null
        },
        pending: false,
        error: null
    },
    documents: {
        byId: {},
        allIds: [],
        editing: {},
        created: {
            status: false,
            document: null
        },
        edited: {
            status: false,
            message: null
        },
        pending: false,
        error: null
    },
    documentModels: {
        byId: {},
        allIds: [],
        editing: {},
        created: {
            status: false,
            document: null
        },
        edited: {
            status: null,
            message: null
        },
        pending: false,
        error: null
    },


}

const docReducer = createReducer(initialState, {
    GET_TYPE_PENDING: (state, action) => {
        state.types.pending = true
    },
    GET_TYPE_SUCCESS: (state, action) => {
        const { payload } = action
        if (payload.entities && payload.entities.types) {
            state.types.byId = merge({}, state.types.byId, payload.entities.types)
            state.types.allIds = merge([], state.types.allIds, Object.keys(payload.entities.types))
            state.types.pending = false
        }
        return state
    },
    GET_TYPE_ERROR: (state, action) => {
        state.types.pending = action.payload;
        state.types.pending = false
    },
    GET_DOCUMENT_MODELS_PENDING: (state, action) => {
        state.documentModels.pending = true
    },
    GET_DOCUMENT_MODELS_SUCCESS: (state, action) => {
        const { payload } = action
        state.documentModels.pending = false

        if (payload.entities && payload.entities.documentModels) {
            state.documentModels.byId = merge({}, state.documentModels.byId, payload.entities.documentModels)
            state.documentModels.allIds = merge([], state.documentModels.allIds, Object.keys(payload.entities.documentModels))
            state.documentModels.error = null
        }
        return state
    },
    GET_DOCUMENT_MODELS_ERROR: (state, action) => {
        state.documentModels.pending = action.payload;
        state.documentModels.pending = false
    },

    GET_DOCUMENTS_PENDING: (state, action) => {
        state.documents.pending = true
    },
    GET_DOCUMENTS_SUCCESS: (state, action) => {
        const { payload } = action
        state.documents.pending = false

        if (payload.entities && payload.entities.documents) {
            state.documents.byId = merge({}, state.documents.byId, payload.entities.documents)
            state.documents.allIds = merge([], state.documents.allIds, Object.keys(payload.entities.documents))
            state.documents.error = null
        }
        return state
    },
    GET_DOCUMENTS_ERROR: (state, action) => {
        state.documents.pending = false
        state.documents.error = action.payload
    },

    ADD_DOCUMENTMODEL_PENDING: (state, action) => {
        state.documentModels.pending = true
    },
    ADD_DOCUMENTMODEL_SUCCESS: (state, action) => {
        const { payload } = action
        if (payload.entities && payload.entities.documentModels) {
            state.documentModels.byId = merge({}, state.documentModels.byId, payload.entities.documentModels)
            state.documentModels.allIds = merge([], state.documentModels.allIds, Object.keys(payload.entities.documentModels))
            state.documentModels.created.status = true;
            state.documentModels.created.document = payload.entities.documentModels;
            state.documentModels.pending = false
            state.documentModels.error = null

        }
        return state
    },
    ADD_DOCUMENTMODEL_ERROR: (state, action) => {
        state.documentModels.error = action.payload;
        state.documentModels.pending = false
    },
    ADD_DOCUMENT_PENDING: (state, action) => {
        state.documents.pending = true
    },
    ADD_DOCUMENT_SUCCESS: (state, action) => {
        const { payload } = action
        if (payload.entities && payload.entities.documents) {
            state.documents.byId = merge({}, state.documents.byId, payload.entities.documents)
            state.documents.allIds = merge([], state.documents.allIds, Object.keys(payload.entities.documents))
            state.documents.created.status = true;
            state.documents.created.document = payload.entities.documents;
            state.documents.pending = false
            state.documents.error = null
        }
        return state
    },
    ADD_DOCUMENT_ERROR: (state, action) => {
        state.documents.error = action.payload;
        state.documents.pending = false
    },
    DELETE_DOCUMENT_PENDING: (state, action) => {
        state.documents.pending = true;
    },
    DELETE_DOCUMENT_SUCCESS: (state, action) => {
        const { payload } = action
        state.documents.pending = false

        if (isNaN(payload)) return state

        const documentId = payload
        if (state.documents.byId[documentId]) {
            const newAllIds = state.documents.allIds
            const filteredIds = newAllIds.filter(value => Number(value) !== documentId)
            state.documents.error = null
            state.documents.allIds = filteredIds
            delete state.documents.byId[documentId]
        }

    },
    DELETE_DOCUMENT_ERROR: (state, action) => {
        state.documents.pending = false
        state.documents.error = action.payload
    },
    DELETE_DOCUMENTMODEL_PENDING: (state, action) => {
        state.documentModels.pending = true;
    },
    DELETE_DOCUMENTMODEL_SUCCESS: (state, action) => {
        const { payload } = action
        state.documentModels.pending = false

        if (isNaN(payload)) return state

        const documentModelId = payload
        if (state.documentModels.byId[documentModelId]) {
            const newAllIds = state.documentModels.allIds
            const filteredIds = newAllIds.filter(value => Number(value) !== documentModelId)
            state.documents.error = null
            state.documentModels.allIds = filteredIds
            delete state.documentModels.byId[documentModelId]
        }

    },
    DELETE_DOCUMENTMODEL_ERROR: (state, action) => {
        state.documentModels.pending = false
        state.documentModels.error = action.payload
    },

    CLEAN_NEW_DOCUMENT_MODEL: (state, action) => {
        state.documentModels.created.status = false
        state.documentModels.created.document = null
    },
    CLEAN_DOCUMENT: state => {
        state.documents.created.status = false
        state.documents.created.document = null
    },
    GET_COMPANY_DOCUMENTS_PENDING: (state, action) => {
        state.documents.pending = true
    },

    GET_COMPANY_DOCUMENTS_SUCCESS: (state, action) => {
        const { payload } = action
        state.documents.pending = false
        if (payload.entities && payload.entities.documents) {
            state.documents.byId = merge({}, state.documents.byId, payload.entities.documents)
            state.documents.allIds = merge([], state.documents.allIds, Object.keys(payload.entities.documents))
            state.documents.error = null
        }
        return state
    },
    GET_COMPANY_DOCUMENTS_ERROR: (state, action) => {
        state.documents.error = action.payload;
        state.documents.pending = false
    },
    EDIT_DOCUMENT_PENDING: (state, action) => {
        state.documents.pending = true;
    },
    EDIT_DOCUMENT_SUCCESS: (state, action) => {
        const { payload } = action
        state.documents.pending = false;
        if (payload.entities && payload.entities.documents) {
            state.documents.byId = merge({}, state.documents.byId, payload.entities.documents)
            state.documents.allIds = merge([], state.documents.allIds, Object.keys(payload.entities.documents))
            state.documents.edited.status = true;
            state.documents.edited.message = "Tutto ok, abbiamo aggiornato il documento!"
            state.documents.error = null
        }
        return state
    },
    EDIT_DOCUMENTMODEL_ERROR: (state, action) => {
        state.documents.pending = false;
        state.documents.error = action.payload
    },
    EDIT_DOCUMENTMODEL_PENDING: (state, action) => {
        state.documentModels.pending = true;
    },
    EDIT_DOCUMENTMODEL_SUCCESS: (state, action) => {
        const { payload } = action
        state.documentModels.pending = false;
        if (payload.entities && payload.entities.documentModels) {
            state.documentModels.byId = merge({}, state.documentModels.byId, payload.entities.documentModels)
            state.documentModels.allIds = merge([], state.documentModels.allIds, Object.keys(payload.entities.documentModels))
            state.documentModels.edited.status = true;
            state.documentModels.edited.message = "Tutto ok, abbiamo aggiornato il documento!"
            state.documentModels.error = null
        }
        return state
    },
    EDIT_DOCUMENTMODEL_ERROR: (state, action) => {
        state.documentModels.pending = false;
        state.documentModels.edited.status = false;
        state.documentModels.edited.message = action.payload.message
        state.documentModels.error = action.payload
    },
})

export default docReducer;
