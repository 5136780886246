export const ScadenzeNav = [
    {
        icon: 'lnr-graduation-hat',
        label: 'Scad. corsi',
        to: '/expiration/education',
    },
    {
        icon: 'lnr-hand',
        label: 'Scad. sicurezza',
        to: '/expiration/security',
    },
    {
        icon: 'lnr-heart',
        label: 'Scad. sanitario',
        to: '/expiration/health',
    },
    {
        icon: 'lnr-cog',
        label: 'Scad. manutenzione',
        to: '/expiration/maintenance',
    },
]

export const ObjectsNav = [
    {
        icon: 'lnr-store',
        label: 'Aziende',
        to: '/company',
    },
    {
        icon: 'lnr-users',
        label: 'Dipendenti',
        to: '/employee',
    }
]

export const ModelNav = [
    {
        icon: 'lnr-paperclip',
        label: 'Documenti',
        to: '/document',
    },
    {
        icon: 'lnr-paperclip',
        label: 'Modelli',
        to: '/documentModel',
    },
]

export const HomeNav = [
    {
        icon: 'lnr-home',
        label: 'Home',
        to: '/',
    },
]