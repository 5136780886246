export const parseForOptions = (data, valueField, labelField, extraLabelField) => {
    if (!Array.isArray(data) || data.length === 0) return [];
    const options = data.map(element => {
        let labelValue = element[labelField];

        if (extraLabelField) labelValue = `${element[labelField]} ${element[extraLabelField]}`

        return {
            value: element[valueField],
            label: labelValue
        }
    })
    return options
}

export const formatDate = date => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}