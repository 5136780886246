import React from 'react'
import cx from 'classnames'
import { TiTickOutline, TiTimesOutline } from 'react-icons/ti'
import Loader from 'react-loaders'

const Messages = ({ pending, messages }) => (

    pending ?
        (
            <>
                <div className="align-self-center pt-5 d-flex align-items-center flex-column">
                    <h4>Solo un attimo...</h4>
                    <Loader type="ball-pulse" />
                </div>
            </>
        )
        :
        messages &&
        <div className={cx("message my-3 shadow", {
            success: messages.status,
            error: !messages.status,
        })}
        >
            {messages.status ? <TiTickOutline className="icons" /> : <TiTimesOutline className="icons" />}
            <span className="text">{messages.message}</span>
        </div>


)

export default Messages;