import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import monitorReducersEnhancer from '../enhancers/monitorReducer'
import loggerMiddleware from '../middleware/logger'
import logger from 'redux-logger'
import rootReducer from '../reducers';
import LogRocket from 'logrocket';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// export default function configureStore(preloadedState) {
//   // if (process.env.NODE_ENV === 'development') {
//   //   middlewares.push(secretMiddleware)
//   // }
//   const middlewares = [thunkMiddleware, loggerMiddleware]
//   const middlewareEnhancer = applyMiddleware(...middlewares)
//   const enhancers = [middlewareEnhancer, monitorReducersEnhancer]
//   const composedEnhancers = composeWithDevTools(...enhancers)
//   const store = createStore(rootReducer, preloadedState, composedEnhancers)
//   return store
// }
if (process.env.NODE_ENV === 'production') {
  LogRocket.init('yxgjs2/chthonic-colzi', {
    release: '0.1.0',
  });
}


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user']
};

const persistedReducers = persistReducer(persistConfig, rootReducer)

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: persistedReducers,
    middleware: [thunkMiddleware, logger, LogRocket.reduxMiddleware(), ...getDefaultMiddleware()],
    preloadedState,
    enhancers: [monitorReducersEnhancer]
  })
  let persistor = persistStore(store)


  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () => store.replaceReducer(rootReducer))
  }

  return { store, persistor }
}