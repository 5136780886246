import Api from '../api/api'
import { createAction } from '@reduxjs/toolkit'
import { normalize, schema } from 'normalizr';
import { company, employee, document, documentModel, type } from '../config/schemas/'

export const getTypesAction = () => {
    return async dispatch => {
        dispatch(getTypePending())
        const response = await Api.getDocumentTypes()
        if (response.message) {
            dispatch(getTypeError(response))
        }
        else {
            const data = response.data;
            dispatch(getTypeSuccess(normalize(data, [type])))
        }
    }
}

export const addDocumentModelAction = (newDocumentModel) => {
    return async dispatch => {
        dispatch(addDocumentModelPending())
        const response = await Api.addDocumentModel(newDocumentModel)
        if (response.message) {
            dispatch(addDocumentModelError(response))
        }
        else {
            const data = response.data;
            dispatch(addDocumentModelSuccess(normalize(data, documentModel)))
        }
    }
}

export const addDocumentAction = (newDocument) => {
    return async dispatch => {
        dispatch(addDocumentPending())
        const response = await Api.addDocument(newDocument)
        if (response.message) {
            dispatch(addDocumentError(response))
        }
        else {
            const data = response.data;
            dispatch(addDocumentSuccess(normalize(data, document)))
        }
    }
}

export const editDocumentAction = (modifiedDocument) => {
    return async dispatch => {
        console.log(modifiedDocument)
        dispatch(editDocumentPending())
        const response = await Api.editDocument(modifiedDocument)
        if (response.message) {
            console.log(response.message)
            dispatch(editDocumentError(response))
        }
        else {
            const data = response.data;
            console.log(data)
            dispatch(editDocumentSuccess(normalize(data, document)))
        }
    }
}

export const editDocumentModelAction = (modifiedDocumentModel) => {
    return async dispatch => {
        console.log(modifiedDocumentModel)
        dispatch(editDocumentModelPending())
        const response = await Api.editDocumentModel(modifiedDocumentModel)
        if (response.message) {
            console.log(response.message)
            dispatch(editDocumentModelError(response))
        }
        else {
            const data = response.data;
            console.log(data)
            dispatch(editDocumentModelSuccess(normalize(data, documentModel)))
        }
    }
}

export const deleteDocumentAction = documentId => {
    return async dispatch => {
        dispatch(deleteDocumentPending())
        const response = await Api.deleteDocument(documentId)
        if (response.message) {
            dispatch(deleteDocumentError(response))
        }
        else {
            dispatch(deleteDocumentSuccess(documentId))
        }
    }
}

export const deleteDocumentModelAction = documentModelId => {
    return async dispatch => {
        dispatch(deleteDocumentModelPending())
        const response = await Api.deleteDocumentModel(documentModelId)
        if (response.message) {
            dispatch(deleteDocumentModelError(response))
        }
        else {
            dispatch(deleteDocumentModelSuccess(documentModelId))
        }
    }
}

export const getDocumentModelsAction = () => {
    return async dispatch => {
        dispatch(getDocumentModelsPending())
        const response = await Api.getDocumentModels()
        if (response.message) {
            dispatch(getDocumentModelsError(response))
        }
        else {
            const data = response.data;
            dispatch(getDocumentModelsSuccess(normalize(data, [documentModel])))
        }
    }
}

export const getDocumentsAction = (documentId) => {
    return async dispatch => {
        dispatch(getDocumentsPending())
        const response = await Api.getDocuments(documentId)
        if (response.message) {
            dispatch(getDocumentsError(response))
        }
        else {
            const data = response.data;
            dispatch(getDocumentsSuccess(normalize(data, [document])))
        }
    }
}

export const getTypePending = createAction("GET_TYPE_PENDING")
export const getTypeError = createAction("GET_TYPE_ERROR")
export const getTypeSuccess = createAction("GET_TYPE_SUCCESS")

export const getDocumentModelsPending = createAction("GET_DOCUMENT_MODELS_PENDING")
export const getDocumentModelsError = createAction("GET_DOCUMENT_MODELS_ERROR")
export const getDocumentModelsSuccess = createAction("GET_DOCUMENT_MODELS_SUCCESS")

export const getDocumentsPending = createAction("GET_DOCUMENTS_PENDING")
export const getDocumentsError = createAction("GET_DOCUMENTS_ERROR")
export const getDocumentsSuccess = createAction("GET_DOCUMENTS_SUCCESS")

export const addDocumentModelPending = createAction("ADD_DOCUMENTMODEL_PENDING")
export const addDocumentModelError = createAction("ADD_DOCUMENTMODEL_ERROR")
export const addDocumentModelSuccess = createAction("ADD_DOCUMENTMODEL_SUCCESS")

export const addDocumentPending = createAction("ADD_DOCUMENT_PENDING")
export const addDocumentError = createAction("ADD_DOCUMENT_ERROR")
export const addDocumentSuccess = createAction("ADD_DOCUMENT_SUCCESS")

export const editDocumentPending = createAction("EDIT_DOCUMENT_PENDING")
export const editDocumentError = createAction("EDIT_DOCUMENT_ERROR")
export const editDocumentSuccess = createAction("EDIT_DOCUMENT_SUCCESS")

export const editDocumentModelPending = createAction("EDIT_DOCUMENTMODEL_PENDING")
export const editDocumentModelError = createAction("EDIT_DOCUMENTMODEL_ERROR")
export const editDocumentModelSuccess = createAction("EDIT_DOCUMENTMODEL_SUCCESS")

export const cleanUpNewDocumentModelAction = createAction("CLEAN_NEW_DOCUMENT_MODEL")
export const cleanUpDocumentAction = createAction("CLEAN_DOCUMENT")

export const deleteDocumentPending = createAction("DELETE_DOCUMENT_PENDING")
export const deleteDocumentError = createAction("DELETE_DOCUMENT_ERROR")
export const deleteDocumentSuccess = createAction("DELETE_DOCUMENT_SUCCESS")

export const deleteDocumentModelPending = createAction("DELETE_DOCUMENTMODEL_PENDING")
export const deleteDocumentModelError = createAction("DELETE_DOCUMENTMODEL_ERROR")
export const deleteDocumentModelSuccess = createAction("DELETE_DOCUMENTMODEL_SUCCESS")