import React from "react"
import styled from "styled-components"
import {
  Heading,
  HeadingLink,
  LinkList,
  DropdownSection,
  Icon
} from "./Components"
import { Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa'

const CompanyDropdownEl = styled.div`
  width: 18.5rem;
`

const CompanyDropdown = () => {
  return (
    <CompanyDropdownEl>
      <DropdownSection data-first-dropdown-section>
        {/* <Heading>Documenta ion</Heading> */}
        <p className="menu-link font-size-md">Visualizza la lista di aziende e dipendenti, o utilizza le funzioni di aggiunta rapida. </p>
        <div className="pt-3">
          <Link to="/company/add/" className="my-2">
            <Button className="add-something-btn d-flex text-left align-items-center">
              <div className="mr-3">
                <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
              </div>
              <div className="menu-link ">
                Inserisci nuova <b>azienda</b>
              </div>
            </Button>
          </Link>
          <Link to="/employee/add/">
            <Button className="add-something-btn my-2 d-flex text-left align-items-center">
              <div className="mr-3">
                <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
              </div>
              <div className="menu-link">
                Inserisci nuovo <b>dipendente</b>
              </div>
            </Button>
          </Link>
        </div>
        <div className="pt-3">
          <HeadingLink>
            <Link to="/company/" className="menu-link">
              Tutte le aziende
          </Link>
          </HeadingLink>
          <HeadingLink >
            <Link to="/employee/" className="menu-link">
              Tutti i dipendenti
          </Link>
          </HeadingLink>
        </div>

      </DropdownSection>
    </CompanyDropdownEl>
  )
}

export default withRouter(CompanyDropdown)
