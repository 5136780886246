import React from 'react'
import {
    PDFDownloadLink,
    Document,
    Page,
    View,
    Text,
    StyleSheet,
    Font
} from '@react-pdf/renderer'
import font from '../../../assets/fonts/circular-bold.ttf'

Font.register({ family: 'CircularStd', src: font, fontStyle: 'normal', fontWeight: 'normal' });

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        fontFamily: 'CircularStd'
    },
    section: {
        margin: 20,
        padding: 20,
        flexGrow: 1
    },
    header: {
        border: '1px solid red',
        marginBottom: 50
    },
    docTitle: {
        fontWeight: 900,
        fontSize: 30,
    },
    docSubtitle: {
        fontWeight: 500,
        fontSize: 20
    },
    documentList: {
        border: '0 solid gray',
    },
    secTitle: {
        fontSize: 20,
        color: 'gray',
        marginBottom: 10
    },
    singleDoc: {
        border: '1 solid black',
        borderRadius: 20,
        padding: 30,
        margin: '5 0'
    },
    employeeList: {
        border: '1 solid blue',

    },
    singleEmp: {
        border: '1 solid black',
    }
});

// Create Document Component
const MyDocument = ({ company, documents, employees }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <View style={styles.header}>
                    <Text style={styles.docTitle}>{company.business_name}</Text>
                    <Text style={styles.docSubtitle}>Documenti: {documents.length}</Text>
                    <Text style={styles.docSubtitle}>Dipendenti: {employees.length}</Text>
                </View>
                <View style={styles.documentList}>
                    <Text style={styles.secTitle}>Lista Documenti:</Text>
                    {documents.map((document, index) => (
                        <View key={index} style={styles.singleDoc}>
                            <Text style={styles.docSubtitle}>{document.documentmodel_id}</Text>
                            <Text>Data inizio validità: {document.start_date}</Text>
                            <Text>Data fine validità: {document.expiration_date}</Text>
                            <Text>Assegnato a {document.employee_id || '...'}</Text>
                        </View>
                    ))}
                </View>
                <View style={styles.employeeList}>
                    <Text style={styles.secTitle}>Lista Dipendenti:</Text>
                    {employees.map((employee, index) => (
                        <View key={index} style={styles.singleEmp}>
                            <Text>{employee.first_name} {employee.last_name}</Text>
                            <Text>Telefono: {employee.telephone_number}</Text>
                            <Text>Email: {employee.email}</Text>
                            <Text>Data di nascita: {employee.birth_date}</Text>
                        </View>
                    ))}
                </View>
            </View>
        </Page>
    </Document >
);

export default MyDocument