import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';
import ResizeDetector from 'react-resize-detector';
import { ToastContainer } from 'react-toastify';
import AppHeader from '../../layout/AppHeader/';
import AppSidebar from '../../layout/AppSidebar/';
import ThemeOptions from '../../layout/ThemeOptions/';
import Index from '../../pages/';
import Login from '../../pages/Login/'

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closedSmallerSidebar: false
        };

    }

    render() {
        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
            isAuthenticated
        } = this.props;
        console.log(isAuthenticated)
        return (
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <Switch>
                        <Route path="/login">
                            <Login />
                        </Route>

                        <Fragment>
                            <div className="wrapper">

                                <div className={cx(
                                    "app-container app-theme-" + colorScheme,
                                    { 'fixed-header': enableFixedHeader },
                                    { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                                    { 'fixed-footer': enableFixedFooter },
                                    { 'closed-sidebar': enableClosedSidebar || width < 1250 },
                                    { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                                    { 'sidebar-mobile-open': enableMobileMenu },
                                    { 'body-tabs-shadow-btn': enablePageTabsAlt },
                                )}>
                                    {isAuthenticated
                                        ?
                                        (
                                            <>
                                                <ThemeOptions />
                                                <AppHeader />
                                                <div className="app-main">
                                                    <AppSidebar />
                                                    <div className="app-main__outer">
                                                        <div className="app-main__inner mt-md-5">
                                                            <Route>
                                                                <Index />
                                                            </Route>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                        )
                                        :
                                        (<Redirect
                                            push to={{ pathname: "/login" }}
                                        />)}
                                </div>
                            </div>
                        </Fragment>
                    </Switch>
                )}
            />
        )
    }
}



const mapStateToProp = state => {

    const isAuthenticated = state.user.user && Object.entries(state.user.user).length !== 0;

    return {
        colorScheme: state.ThemeOptions.colorScheme,
        enableFixedHeader: state.ThemeOptions.enableFixedHeader,
        enableMobileMenu: state.ThemeOptions.enableMobileMenu,
        enableFixedFooter: state.ThemeOptions.enableFixedFooter,
        enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
        enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
        enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
        isAuthenticated
    }
};

export default withRouter(connect(mapStateToProp)(Main));
