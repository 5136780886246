import { createReducer } from '@reduxjs/toolkit'
import { merge, groupBy, remove } from 'lodash'

const initialState = {
    byId: {},
    allIds: [],
    created: {
        status: null,
        company: null,
        message: null
    },
    edited: {
        status: null,
        company: null
    },
    pending: false,
    error: null
}

const companyReducer = createReducer(initialState, {

    DELETE_COMPANY_PENDING: (state, action) => {
        state.pending = true;
    },
    DELETE_COMPANY_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false

        if (isNaN(payload)) return state

        const companyId = payload
        if (state.byId[companyId]) {
            const newAllIds = state.allIds
            const filteredIds = newAllIds.filter(value => Number(value) !== companyId)

            state.allIds = filteredIds
            delete state.byId[companyId]
        }

    },
    DELETE_COMPANY_ERROR: (state, action) => {
        state.pending = false
        return state
    },
    EDIT_COMPANY_PENDING: (state, action) => {
        state.pending = true;
    },
    EDIT_COMPANY_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false;
        if (payload.entities && payload.entities.companies) {
            state.byId = merge({}, state.byId, payload.entities.companies)
            state.allIds = merge([], state.allIds, Object.keys(payload.entities.companies))
            state.edited.status = true;
            state.edited.message = "Tutto ok, abbiamo aggiornato l'azienda!"
        }
        return state
    },
    EDIT_COMPANY_ERROR: (state, action) => {
        state.edited.status = false;
        state.edited.message = action.payload.message
        state.pending = false;
    },
    ADD_COMPANY_PENDING: (state, action) => {
        state.pending = true;
    },
    ADD_COMPANY_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false

        if (payload.entities && payload.entities.companies) {
            state.byId = merge({}, state.byId, payload.entities.companies)
            state.allIds = merge([], state.allIds, Object.keys(payload.entities.companies))
            state.created.status = true;
            state.created.message = "Abbiamo aggiunto l'azienda!"
            state.created.company = Object.values(payload.entities.companies)
        }
        return state
    },
    ADD_COMPANY_ERROR: (state, action) => {
        state.pending = false
        state.created.status = false;
        state.created.message = action.payload.message
        return state
    },


    GET_COMPANIES_PENDING: (state, action) => {
        state.pending = true
    },
    GET_COMPANIES_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false

        if (payload.entities && payload.entities.companies) {
            state.byId = merge({}, state.byId, payload.entities.companies)
            state.allIds = merge([], state.allIds, Object.keys(payload.entities.companies))
        }
        return state
    },
    GET_COMPANIES_ERROR: (state, action) => {
        state.error = action.payload;
        state.pending = false
    },
    GET_COMPANY_EMPLOYEES_PENDING: (state, action) => {
        state.pending = true
    },

    GET_COMPANY_EMPLOYEES_SUCCESS: (state, action) => {
        // mergia la lista di id degli employee sull'id della company
        // qui arriva perfetto adesso
        const { payload } = action
        state.pending = false

        if (payload.entities && payload.entities.companies) {
            state.byId = merge({}, state.byId, payload.entities.companies)
        }
        return state

        // state.companyEmployees = action.payload;
        // state.pending = false
    },
    GET_EMPLOYEES_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false

        if (payload.entities && payload.entities.employees) {
            const { payload: { entities: { employees } } } = action
            const empGroupByCompany = groupBy(employees, emp => emp.company_id)
            for (let companyId of Object.keys(empGroupByCompany)) {
                if (state.byId[companyId]) {
                    state.byId[companyId].employees = empGroupByCompany[companyId].map(emp => emp.id)
                }
            }
        }
        return state
    },
    ADD_EMPLOYEE_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false

        if (payload.entities && payload.entities.employees) {
            const { payload: { entities: { employees } } } = action
            console.log(employees)
            for (let employee of Object.values(employees)) {
                console.log(employee)
                const companyId = employee.company_id
                // console.log(merge([], state.byId[companyId].employees, [employee.id]))
                const arrayToBeMerged = [employee.id]
                state.byId[companyId].employees = merge([], state.byId[companyId].employees, arrayToBeMerged)
            }

        }
        return state
    },


    GET_DOCUMENTS_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false

        if (payload.entities && payload.entities.documents) {
            const { payload: { entities: { documents } } } = action
            const docGroupByCompany = groupBy(documents, doc => doc.company_id)
            for (let companyId of Object.keys(docGroupByCompany)) {
                if (state.byId[companyId]) {
                    state.byId[companyId].documents = docGroupByCompany[companyId].map(doc => doc.id)


                }
            }
        }
        return state
    },

    GET_COMPANY_EMPLOYEES_ERROR: (state, action) => {
        state.error = action.payload;
        state.pending = false
    },
    GET_COMPANY_DOCUMENTS_PENDING: (state, action) => {
        state.pending = true
    },

    GET_COMPANY_DOCUMENTS_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false

        if (payload.entities && payload.entities.documents) {
            state.byId = merge({}, state.byId, payload.entities.companies)

        }
        return state
    },
    GET_COMPANY_DOCUMENTS_ERROR: (state, action) => {

        state.error = action.payload;
        state.pending = false
    },
    CLEAN_NEW_COMPANY: (state, action) => {
        state.created.status = null;
        state.created.message = null;
        state.created.company = null;
    },
    CLEAN_EDIT_COMPANY: (state, action) => {
        state.edited.status = null;
        state.edited.message = null;
        state.pending = false;
    }
})

export default companyReducer