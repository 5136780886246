import React, { Component, Fragment } from 'react';
import {
    Col, Button
} from 'reactstrap'
import { FaPlus, FaFileDownload, FaRegFile } from 'react-icons/fa'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { PageTitle, DataTable } from '../../../components/'
import { getCompaniesAction } from '../../../actions/companyAction'
import { getEmployeesAction, deleteEmployeeAction} from '../../../actions/employeesAction'
import { getDocumentsAction } from '../../../actions/documentAction'
import { groupBy } from 'lodash'

const RapidAction = () => (
    <Col md="3" className="pr-5 d-flex flex-column justify-content-start align-items-end">
        <h4 className="mt-2 mb-3 mr-2 text-black">
            Azioni rapide
                                        </h4>
        <Link to='/employee/add'>
            <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
                <div className="mr-3">
                    <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
                </div>
                <div className="text-black">
                    Inserisci nuovo dipendente
                </div>
            </Button>
        </Link>
        <Button disabled={true} className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
            <div className="mr-3">
                <FaFileDownload className="hoverable-icon static brand p-2"></FaFileDownload>
            </div>
            <div className="text-black">
                Esporta tabella in .csv
                </div>
        </Button>
    </Col>
)
class EmployeeList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            employees: []
        }
    }

    renderCell = (field, value, row) => {
        switch (field) {
            case "id":
                return (
                    <td className="text-center text-muted p-3" name={field}>
                        {value}
                    </td>
                );

            // case "name":
            // case "surname":
            //     return (
            //         <td className="text-center p-3" name={field}>
            //             {value}
            //         </td>
            //     );

            case "company_id":
                return (
                    <td className="text-center p-3" name={field}>
                        {this.props.companies.byId[value] ?
                            (
                                <Link to={`/company/edit/${value}`}>{this.props.companies.byId[value].business_name}</Link>
                            ) :
                            (
                                <a href="#">Errore nel recuperare l'azienda</a>
                            )

                        }

                    </td>
                )

            case "documentsId":
                return (
                    <td className="text-center p-3" name={field}>
                        {
                            this.props.employees.byId[row.original.id].documents ?
                                (this.props.employees.byId[row.original.id].documents.length) :
                                "0"
                        }
                    </td>
                )

            default:
                return (
                    <td className="text-center p-3" name={field}>
                        {value}
                    </td>
                );
        }
    };



    async componentDidMount() {
        const companies = await this.props.getCompanies()
        const employees = await this.props.getEmployees()
        const documents = await this.props.getDocuments()
    }


    handleDelete = id => {
        this.props.deleteEmployee(id)
    }

    render() {
        const { employees } = this.props;
        return (
            <Fragment>

                <PageTitle
                    heading="Dipendenti"
                    subheading="La lista di tutti i dipendenti inseriti nel gestionale."
                    icon={null}
                    color={false}
                ><RapidAction></RapidAction></PageTitle>
                {/* <StandardTable
                                title="Test"
                                renderCell={this.renderCell}
                                filterTable={this.filterTable}
                                data={this.state.data}
                                columns={["Id", "Nome", "Rag. Soc.", "Settore", "P. Iva", "Indirizzo", "Scadenze", "Data",]}
                            /> */}
                <DataTable title="Cerca:"
                    handleDelete={this.handleDelete}
                    columns={[
                        {
                            Header: 'Id',
                            accessor: 'id'
                        },
                        {
                            Header: 'Nome',
                            accessor: 'first_name'
                        },
                        {
                            Header: 'Cognome',
                            accessor: 'last_name'
                        },
                        {
                            Header: 'Azienda',
                            accessor: 'company_id'
                        },
                        {
                            Header: 'Documenti',
                            accessor: 'documentsId'
                        },
                    ]} data={Object.values(employees.byId)} model={"employee"} renderCell={this.renderCell}
                />

            </Fragment>

        )
    }
}

const mapDispatchToProps = dispatch => ({
    getCompanies: () => dispatch(getCompaniesAction()),
    getEmployees: () => dispatch(getEmployeesAction()),
    getDocuments: () => dispatch(getDocumentsAction()),
    deleteEmployee: employeeId => dispatch(deleteEmployeeAction(employeeId))
})

const mapStateToProps = state => {
    const employees = state.employees
    const companies = state.companies
    const documents = state.documents.documents
    const docGroupByEmployee = groupBy(documents.byId, doc => doc.employee)
    console.log(docGroupByEmployee)
    return { employees, companies, document, docGroupByEmployee }
}


export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList)
