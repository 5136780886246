import React, { Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router';
import AppHeader from '../../layout/AppHeader/';
import AppSidebar from '../../layout/AppSidebar/';
import ThemeOptions from '../../layout/ThemeOptions/';
// import Company from './view/Company'
import CompanyList from './list/';
import CompanyEdit from './edit/';
import CompanyAdd from './add/';
import EmployeeAdd from '../Employee/add/'
import NotFound from '../../pages/NotFound'


class Company extends React.Component {
    render() {
        return (

            <Switch>
                <Route path="/company" exact component={CompanyList} />
                <Route path="/company/edit/:id" exact component={CompanyEdit} />
                <Route path="/company/add" exact component={CompanyAdd} />
                <Route path="/employee/add" exact component={EmployeeAdd} />
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>


        );
    }
}

export default Company;
