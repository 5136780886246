import React from 'react'
import Home from './Home'
import NotFound from './NotFound'
import Login from './Login'
import PrivateRoute from '../components/PrivateRoute'
import Company from './Company'
import Document from './Document'
import DocumentModel from './DocumentModel'
import Employee from './Employee'
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';

const Index = () => (
    <Switch>
        <Route exact path="/">
            <Home />
        </Route>

        <Route path="/document">
            <Document />
        </Route>

        <Route path="/documentModel">
            <DocumentModel />
        </Route>

        <Route path="/company">
            <Company />
        </Route>

        <Route path="/employee">
            <Employee />
        </Route>
        <Route component={NotFound} />

    </Switch>
)

export default withRouter(connect()(Index));
