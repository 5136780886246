import { createReducer } from '@reduxjs/toolkit'

const initialState = {
    user: {},
    pending: false,
    error: null
}

const authReducer = createReducer(initialState, {
    LOGIN_USER_PENDING: (state, action) => {
        state.pending = true;
    },
    LOGIN_USER_SUCCESS: (state, action) => {
        state.pending = false;
        state.user = action.payload;
        state.error = null;
    },
    LOGIN_USER_ERROR: (state, action) => {
        state.pending = false;
        state.error = action.payload;
    },
    LOGOUT_USER: (state) => ({
        state: undefined
    })
})

export default authReducer;

