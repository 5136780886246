import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Button, Badge } from 'reactstrap'
import { FaPlus, FaFileDownload, FaFile } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { PageTitle, DataTable } from '../../../components/';
import { getCompaniesAction } from '../../../actions/companyAction'
import { getEmployeesAction } from '../../../actions/employeesAction'
import {
    getDocumentsAction,
    getDocumentModelsAction,
    getTypesAction,
    deleteDocumentModelAction
} from '../../../actions/documentAction'
import { typeImages, errorImg, confirmImg } from '../../../assets/images/';

const RapidAction = () => (
    <Col md="3" className="pr-5 d-flex flex-column justify-content-start align-items-end">
        <h4 className="mt-2 mb-3 mr-2 text-black">
            Azioni rapide
                                        </h4>
        <Link to='/documentModel/add'>
            <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
                <div className="mr-3">
                    <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
                </div>
                <div className="text-black">
                    Inserisci nuovo modello
                </div>
            </Button>
        </Link>
        <Button disabled={true} className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
            <div className="mr-3">
                <FaFileDownload className="hoverable-icon static brand p-2"></FaFileDownload>
            </div>
            <div className="text-black">
                Esporta tabella in .csv
                </div>
        </Button>
    </Col>
)

class DocumentModelList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: []
        }
    }

    renderCell = (field, value, row) => {
        switch (field) {
            case "id":
                return (
                    <td className="text-center text-muted p-3" name={field}>
                        {value}
                    </td>
                );

            case "documenttype_id":
                const { types } = this.props
                if (types.byId[value]) {
                    const img = typeImages[types.byId[value].name]
                    return (

                        <td className="text-center p-3" name={field} >
                            <Badge color={types.byId[value].extrafields.color}>
                                {this.props.types.byId[value].name}
                            </Badge>
                        </td >
                    )
                }

            default:
                return (
                    <td className="text-center p-3" name={field}>
                        {value}
                    </td>
                );
        }
    };

    async componentDidMount() {
        await this.props.getTypes()
        await this.props.getDocumentModels()
        await this.props.getDocuments()
    }

    handleDelete = id => {
        this.props.deleteDocumentModel(id)
    }

    render() {

        const { documents, documentModels, companies, types } = this.props;

        return (
            <Fragment>

                <PageTitle
                    heading="Modelli di documento"
                    subheading="La lista di tutti i modelli di documenti inseriti nel gestionale."
                    icon={false}
                    bread=""
                >
                    <RapidAction />
                </PageTitle>
                {
                    documentModels &&
                    <DataTable
                        handleDelete={this.handleDelete}
                        title="Cerca:"
                        columns={[
                            {
                                Header: 'ID',
                                accessor: 'id'
                            },
                            {
                                Header: 'Nome',
                                accessor: 'name'
                            },
                            {
                                Header: 'Scadenza (mesi)',
                                accessor: 'expiration'
                            },
                            {
                                Header: 'Tipo',
                                accessor: 'documenttype_id'
                            },
                        ]}
                        data={Object.values(documentModels)}
                        model={"documentModel"}
                        renderCell={this.renderCell}
                    />
                }

            </Fragment>

        )
    }
}

const mapDispatchToProps = dispatch => ({
    getCompanies: () => dispatch(getCompaniesAction()),
    getEmployees: () => dispatch(getEmployeesAction()),
    getDocuments: () => dispatch(getDocumentsAction()),
    getDocumentModels: () => dispatch(getDocumentModelsAction()),
    getTypes: () => dispatch(getTypesAction()),
    deleteDocumentModel: documentModelId => dispatch(deleteDocumentModelAction(documentModelId))

})

const mapStateToProps = state => {
    const types = state.documents.types;
    const documents = state.documents.documents.byId
    const documentModels = state.documents.documentModels.byId
    const companies = state.companies

    return { documents, documentModels, companies, types }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentModelList)