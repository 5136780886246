import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router';
import AppHeader from '../../layout/AppHeader/';
import AppSidebar from '../../layout/AppSidebar/';
import ThemeOptions from '../../layout/ThemeOptions/';
import DocumentAdd from './add/';
import DocumentList from './list/';
import DocumentEdit from './edit/'
import NotFound from '../../pages/NotFound'


class Document extends React.Component {
    render() {
        return (
            <Switch>
                <Route path="/document/edit/:id" exact component={DocumentEdit} />
                <Route path="/document/add/" exact component={DocumentAdd} />
                <Route path="/document" exact component={DocumentList} />
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        );
    }
}

export default withRouter(connect()(Document));
