import React, { Component } from 'react';
import {
    Jumbotron, Button,
    Breadcrumb, BreadcrumbItem,
    Row, Col
} from 'reactstrap'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux';
import cx from 'classnames';
import { companyImg } from '../../assets/images'
import './index.scss'
class PageTitle extends Component {

    render() {
        let {
            enablePageTitleIcon,
            enablePageTitleSubheading,
            heading,
            icon,
            subheading,
            documents,
            employees,
            color,
            company,
            children,
            bread,
            expiration,
            expirationDate
        } = this.props;

        return (
            <>
                {
                    icon ? (
                        <div className="app-page-title">
                            <div className="page-title-wrapper">
                                <div className="page-title-heading">
                                    {icon && <div
                                        className={cx("page-title-icon", { 'd-none': !enablePageTitleIcon })}>
                                        {icon}
                                    </div>}
                                    <div>
                                        <h1 className="font-weight-bold">{heading}</h1>
                                        <div
                                            className={cx("page-title-subheading", { 'd-none': !enablePageTitleSubheading })}>
                                            {subheading}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                            <div
                                className={cx("standard-radius border-b-light mb-5 pt-5 pb-4 px-5",
                                    {
                                        'bg-primary': color && color !== 'transp',
                                        'text-white': color,
                                        'bg-white': !color,
                                    }
                                )}>
                                <Row>

                                    <Col>
                                        <div className="d-flex flex-row">
                                            <Breadcrumb tag="nav" className="d-flex new-breadcrumb mr-3" listTag="div">
                                                <BreadcrumbItem tag="a" href="#">{bread}</BreadcrumbItem>
                                            </Breadcrumb>
                                        </div>


                                        <h1 className={cx("display-4  ", { 'text-white': color })}>{heading}</h1>
                                        <p className="lead">{subheading}  </p>
                                        {(documents || documents === 0) &&
                                            (<Button className="mb-2 mr-2 bg-light-brand bigger" color="primary">
                                                Documenti <span className="ml-1">{documents}</span>
                                            </Button>)
                                        }
                                        {(employees || employees === 0) &&
                                            (<Button className="mb-2 mr-2 bigger" color="focus">
                                                Dipendenti <span className="ml-1">{employees}</span>
                                            </Button>)
                                        }
                                        {(expiration && expirationDate) &&
                                            (
                                                <>
                                                    <Button className="mb-2 mr-2 bigger" color="focus">
                                                        Durata <span className="ml-1">{expiration} mesi</span>
                                                    </Button>
                                                    <Button className="mb-2 mr-2 bigger" color="focus">
                                                        Scade il <span className="ml-1">{expirationDate}</span>
                                                    </Button>
                                                </>
                                            )
                                        }
                                    </Col>

                                    {children}
                                </Row>
                            </div>
                        )
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    enablePageTitleIcon: state.ThemeOptions.enablePageTitleIcon,
    enablePageTitleSubheading: state.ThemeOptions.enablePageTitleSubheading,
});

const mapDispatchToProps = dispatch => ({});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageTitle));
