import React from 'react'
import { useTable, usePagination, useGlobalFilter, useSortBy } from 'react-table'
import {
    Button, CardHeader,
    CardBody, CardFooter,
    Card, Label,
    Col, FormGroup,
    Row,
} from "reactstrap";
import FormJson from "react-jsonschema-form";
import { TooltipDark, ActionTooltip, ViewTooltip, FormInfo } from '../../../components'
import { Link } from 'react-router-dom'
import { FaFilter, FaPlus, FaFileExport } from 'react-icons/fa'
import { FiEdit3, FiTrash2, FiEye, FiRefreshCw } from 'react-icons/fi'
import cx from 'classnames'


function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter
}) {
    // const count = preGlobalFilteredRows.length;
    return (
        <span>
            <input
                type="text"
                className="ml-3"
                value={globalFilter || ""}
                onChange={e => {
                    console.log("value is changing to:", e.target.value, "previous was", globalFilter, e.target)
                    e.target.value !== globalFilter && setGlobalFilter(e.target.value); // Set undefined to remove the filter entirely
                }}
                placeholder={`scrivi qui per cercare...`}
                style={{
                    fontSize: "1.1rem",
                    border: "0"
                }}
            />
        </span>
    );
}

function DataTable({
    title, columns, data, renderCell,
    model, handleDelete, schema, hasViewTooltip,
    children, availableFilter, loadingRowId,
    handleRenew }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable(
        {
            columns,
            data,
            pageIndex,
            initialState: { pageIndex: 0, pageSize: 50 },
            autoResetGlobalFilters: false,
        },
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        usePagination,
    )

    // Render the UI for your table
    return (
        <>
            <Card className="p-3 mb-4">{title && (
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <icon className="pe-7s-search mr-2"> </icon>
                    {title}
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                    />
                </div>
            )}
            </Card>
            <div className="mb-3 d-flex flex-row align-items-center">
                <span className="mr-2 subheading">Filtri disponibili:</span>{availableFilter}

            </div>
            <Card className="main-card mb-3">
                <CardHeader className="card-head justify-content-between">
                    <div className="d-inline">
                        <span className="mr-2">Filtri attivi:</span> {children}
                        {/* <Button outline color="secondary"><FaFilter className="mr-2" />Filtra</Button> */}
                    </div>
                    {/* <div>
                        <Link to={`/${model}/add`}>
                            <Button outline className=" justify-content-end" color="primary"><FaPlus className="mr-2" />Aggiungi nuovo</Button>
                        </Link>
                        <Link to={{ pathname: `/${model}/export`, state: linkData ? linkData.state : null }} >
                            <Button outline className=" mx-2 justify-content-end" color="primary"><FaFileExport className="mr-2" />Esporta</Button>
                        </Link>
                    </div> */}
                </CardHeader>
                <CardBody className="p-0">
                    <div className="table-responsive p-0">
                        <table className="table" {...getTableProps()}>
                            <thead>
                                {headerGroups.map((headerGroup, index) => (
                                    <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column, index) => (
                                            <th key={index} className="text-center p-md-3" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.render('Header')}
                                                <span>
                                                    {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                                </span>
                                            </th>
                                        ))}
                                        <th className="text-center p-3">Azioni</th>
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {page.map((row, i) => {
                                    prepareRow(row)
                                    return (
                                        <tr key={i} className={cx("hoverable", {
                                            "loading": loadingRowId === row.original.id
                                        })} {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return renderCell(cell.column.id, cell.value, row)
                                            })}
                                            <td className="text-center">
                                                {
                                                    handleRenew &&
                                                    <>
                                                        <ActionTooltip
                                                            id={`item-renew-${i}`}
                                                            handleAction={handleRenew}
                                                            item={{ placement: "top" }}
                                                            actionText="Rinnova"
                                                            object={row.original}
                                                            color="brand"
                                                            buttonColor="success"
                                                            title="Rinnova"
                                                            forceToggle={true}
                                                            renew={true}
                                                            dates={{
                                                                start: row.original.start_date,
                                                                end: row.original.expiration_date
                                                            }}
                                                            text={"Il documento acquisisce la data di inizio a partire dalla data di scadenza"}
                                                        >


                                                        </ActionTooltip>
                                                        <Button id={`Tooltip-item-renew-${i}`} color="blue-light">
                                                            <FiRefreshCw className="hoverable-icon success" size={20} />
                                                        </Button>
                                                    </>
                                                }
                                                {
                                                    hasViewTooltip &&
                                                    <>
                                                        <Button id={`Tooltip-item-view-${i}`} color="blue-light">
                                                            <FiEye className="hoverable-icon success" size={20} />
                                                        </Button>
                                                        <ViewTooltip id={`item-view-${i}`} item={{ placement: "left" }} text={"Visualizza"}>
                                                            <FormJson
                                                                schema={schema}
                                                                formData={row.original}
                                                            >
                                                                <></>
                                                            </FormJson>
                                                        </ViewTooltip>
                                                    </>
                                                }
                                                <Link to={`/${model}/edit/${row.original.id}`}>
                                                    <TooltipDark id={`item-edit-${i}`} item={{ placement: "top" }} text={"Modifica"}></TooltipDark>
                                                    <Button id={`Tooltip-item-edit-${i}`} color="blue-light">
                                                        <FiEdit3 className="hoverable-icon primary" size={20} />
                                                    </Button>
                                                </Link>
                                                {
                                                    handleDelete &&
                                                    <>
                                                        <ActionTooltip
                                                            id={`item-delete-${i}`}
                                                            handleAction={() => handleDelete(row.original.id)}
                                                            item={{ placement: "top" }}
                                                            actionText="Elimina"
                                                            text="Sei sicuro di voler eliminare questo oggetto?"
                                                            title="Elimina"
                                                            buttonColor="danger"
                                                            forceToggle={false}
                                                        />
                                                        <Button id={`Tooltip-item-delete-${i}`} color="blue-light">
                                                            <FiTrash2 className="hoverable-icon danger" size={20} />
                                                        </Button>
                                                    </>

                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
                <CardFooter className="justify-content-center">
                    <div className="pagination justify-content-center mt-3 mb-3">
                        <button type="button" className="btn btn-light mr-1" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {'<<'}
                        </button>{' '}
                        <button type="button" className="btn btn-light mr-1" onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {'<'}
                        </button>{' '}
                        <button type="button" className="btn btn-light mr-1" onClick={() => nextPage()} disabled={!canNextPage}>
                            {'>'}
                        </button>{' '}
                        <button type="button" className="btn btn-light mr-3" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {'>>'}
                        </button>{' '}
                        <h6 className="align-self-end mr-3">
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{' '}
                        </h6>
                        <h6 className="align-self-end mr-3">
                            | Go to page:{' '}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={e => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    gotoPage(page)
                                }}
                                style={{ width: '100px' }}
                            />
                        </h6>{' '}
                        <select

                            value={pageSize}
                            onChange={e => {
                                setPageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </CardFooter>
            </Card>
        </>
    )
}

export default DataTable;

