import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap'
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MetisMenu from 'react-metismenu';
import { FaPlus } from 'react-icons/fa'
import RouterLink from 'react-metismenu-router-link';
import {
    setEnableMobileMenu
} from '../../reducers/ThemeOptions';
import { HomeNav, ScadenzeNav, ObjectsNav, ModelNav } from './NavItems';

class Nav extends Component {

    state = {};

    toggleMobileSidebar = () => {
        let { enableMobileMenu, setEnableMobileMenu } = this.props;
        console.log("test")
        setEnableMobileMenu(!enableMobileMenu);
    }

    render() {
        let { enableMobileMenu, setEnableMobileMenu } = this.props;

        return (
            <Fragment>
                {/* <MetisMenu content={HomeNav} LinkComponent={RouterLink} onSelected={this.toggleMobileSidebar} activeLinkFromLocation className="vertical-nav-menu mb-3  " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" /> */}
                <MetisMenu content={HomeNav} LinkComponent={RouterLink} onSelected={() => this.toggleMobileSidebar} activeLinkFromLocation className="vertical-nav-menu " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
                <Link to="/document/add/">
                    <Button className="new-expiration-button py-2 px-3 mb-3  d-flex text-left align-items-center">
                        <div className="mr-3">
                            <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
                        </div>
                        <div>
                            Inserisci nuova <b>scadenza</b>
                        </div>
                    </Button>
                </Link>

                <Link to="/company/add/">
                    <Button className="new-expiration-button py-2 px-3 mb-3  d-flex text-left align-items-center">
                        <div className="mr-3">
                            <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
                        </div>
                        <div>
                            Inserisci nuova <b>azienda</b>
                        </div>
                    </Button>
                </Link>

                {/* <MetisMenu content={ScadenzeNav} LinkComponent={RouterLink} onSelected={this.toggleMobileSidebar} activeLinkFromLocation className="vertical-nav-menu mb-1  " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" /> */}
                <MetisMenu content={ObjectsNav} LinkComponent={RouterLink} onSelected={this.toggleMobileSidebar} activeLinkFromLocation className="vertical-nav-menu mb-1  " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
                <MetisMenu content={ModelNav} LinkComponent={RouterLink} onSelected={this.toggleMobileSidebar} activeLinkFromLocation className="vertical-nav-menu mb-1  " iconNamePrefix="" classNameStateIcon="pe-7s-angle-down" />
            </Fragment>
        );
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path);
    }
}
const mapStateToProps = state => ({
    enableMobileMenu: state.ThemeOptions.enableMobileMenu
});

const mapDispatchToProps = dispatch => ({

    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),

});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));