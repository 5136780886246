import React from 'react'
import { Button, Spinner } from 'reactstrap'

const ButtonLoading = ({ loading, children, color, onClick, className, disabled }) => (
    <>
        {
            loading ?
                (<Button disabled={disabled} className={`${className ? className : 'null'} disabled`} color={color || "primary"} >
                    <Spinner size="sm" className="spinner-border mr-2 spinner-border-sm" color="white" />
                    Loading...
            </Button >) :
                (<Button disabled={disabled} className={`${className ? className : 'null'}`} onClick={onClick} color={color || "primary"}>
                    {children}
                </Button>)
        }
    </>
)


export default ButtonLoading