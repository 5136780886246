import React from 'react'
import {
    Button, CardHeader,
    Card, CardBody,
    CardImg, CardFooter,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom'

const errorCard = ({ errorCode, translation, img, model, action, customText, children }) => {

    return (
        <Card>
            <CardBody className="d-flex flex-column align-items-center p-5 c-body-no-head">
                <h4 className="text-center">
                    {customText ?
                        customText :
                        `Non abbiamo trovato ${translation} per ${model && (model.business_name || model.first_name + " " + model.last_name)}`
                    }
                </h4>
                {!customText &&
                    <p className="text-center subheading px-5">
                        Se non ne hai ancora inseriti cliccando qui sotto potrai cominciare ad inserirne di nuovi,
                        se pensi che ci sia stato un errore <a href="#">contattaci</a>.
                    </p>
                }
                {img && <CardImg className="tab-image mt-3" src={img}></CardImg>}
                {children}

                {action && <Link to={{ pathname: action.to || action.pathname, state: action.state || null }}>
                    <Button className="mt-4 btn-shadow" color="primary">
                        {action.cta}
                    </Button>
                </Link>}

            </CardBody>
        </Card>
    )
}

export default withRouter(errorCard);