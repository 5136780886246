import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    Container,
    Col,
    Row,
    Alert,
    Button,
    Card,
    CardImg,
    CardImgOverlay,
    Form, FormGroup, Input, Label,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap'
import {
    PageTitle,
    ButtonLoading,
    ErrorCard
} from '../../../components/'
import { CSSTransition } from 'react-transition-group';
import {
    getTypesAction,
    addDocumentModelAction,
    cleanUpNewDocumentModelAction
} from '../../../actions/documentAction'
import { typeImages, errorImg, confirmImg } from '../../../assets/images/';

class DocumentModelAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedType: '',
            documenttype_id: '',
            modal: '',
            name: '',
            description: '',
            shorthand: '',
            expiration: '',
            showForm: true,
        }

        this.initialState = this.state

    }


    toggle = () => this.setState(prevState => ({ modal: !prevState.modal }));

    componentDidMount() {
        this.props.getTypes();
    }

    componentWillUnmount() {
        this.props.cleanUpNewDocumentModel()
    }

    handleChange = evt => {
        const { value, name } = evt.target
        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }), () => console.log(this.state))
    }

    submitForm = () => {
        this.props.submitForm(this.state)
    }

    resetState = () => {
        this.props.cleanUpNewDocumentModel()
        this.setState(this.initialState)
    }

    render() {

        const { selectedType, modal, name, description, shorthand, expiration, showForm } = this.state;
        const { types, isPending, isCreated, error, errorUnserialized } = this.props;
        return (

            <Container fluid >
                <Row className="justify-content-center flex-row">
                    <Col md={12}>
                        <PageTitle
                            heading="Aggiungi un nuovo modello di documento"
                            subheading="Qui puoi creare nuovi modelli di documenti, questo ti permetterà di inserire velocemente una nuova scadenza
                            selezionando un modello già esistente."
                            icon={null}
                        />
                        <CSSTransition
                            in={isCreated.status}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => this.setState({ showForm: false })}
                            onExited={() => this.setState({ showForm: true })}
                        >
                            <Row>
                                {isCreated.status &&
                                    <ErrorCard img={confirmImg} customText={`Abbiamo aggiunto il modello di documento ${Object.values(isCreated.document)[0].name}!`}>
                                        <Button className="mt-4 btn-shadow" onClick={this.resetState} color="primary">
                                            Continua ad inserire documenti
                                    </Button>
                                    </ErrorCard>
                                }
                            </Row>
                        </CSSTransition>
                        <CSSTransition
                            in={error && !isCreated.status}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => this.setState({ showForm: false })}
                            onExited={() => this.setState({ showForm: true })}
                        >
                            {error && <Alert color="danger d-flex col-md-8 flex-column">
                                <h4 className="alert-heading">{error.message}</h4>
                                <p>
                                    Il server ha risposto: <b>{errorUnserialized.message}</b>
                                </p>
                            </Alert>}
                        </CSSTransition>
                        {showForm && (
                            <div>
                                <Row>
                                    <Col md={12} className="mt-4">
                                        <h4>Seleziona la categoria del nuovo modello o <a href="#" onClick={this.toggle} className="">aggiungine una nuova</a></h4>
                                        <p>Ti aiuterà a distinguere tra diversi tipi di documentie potrai usarle come filtri nelle tabelle</p>
                                    </Col>
                                </Row>
                                <Row className="mb-5">
                                    {types &&
                                        Object.values(types.byId).map((type, index) => {
                                            return (
                                                <Col key={index} md="2">
                                                    <Card className={`document-type-selector d-flex align-items-center ${selectedType === type.name && 'active'}`}
                                                        onClick={() => this.setState({ selectedType: type.name, documenttype_id: type.id })}
                                                    >
                                                        {
                                                            typeImages[type.name] ?
                                                                (
                                                                    <CardImg width={"60%"} className="document-type-selector __image" src={typeImages[type.name].image}></CardImg>
                                                                ) :
                                                                (
                                                                    <p className="document-type-selector text-center mb-0 __text">{type.name.slice(0, 1).toUpperCase()}</p>
                                                                )
                                                        }
                                                        <CardImgOverlay></CardImgOverlay>
                                                    </Card>
                                                    {
                                                        typeImages[type.name] ?
                                                            (
                                                                <h5 className="mt-3 text-center">{typeImages[type.name]._it}</h5>
                                                            ) : (
                                                                <h5 className="mt-3 text-center">{type.name}</h5>
                                                            )
                                                    }
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                                <Form className="form-document mt-5 pt-2">
                                    <Row className="mb-5">
                                        <Col md={7}>
                                            <Row className="mt-5">
                                                <Col md={12} className="mb-3">
                                                    <h4>Inserisci i dati anagrafici del documento</h4>
                                                    <p>Tutti i campi che vedi qui sono richiesti e non potranno essere lasciati vuoti</p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={7}>
                                                    <FormGroup>
                                                        <Label>Nome del documento</Label>
                                                        <Input onChange={this.handleChange} name="name" placeholder="Certificazione di esempio" className=""></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label>Etichetta (alias)</Label>
                                                        <Input onChange={this.handleChange} name="shorthand" placeholder="CERT-ES" className=""></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={11}>
                                                    <FormGroup>
                                                        <Label>Descrizione</Label>
                                                        <Input onChange={this.handleChange} name="description" placeholder="Certificazione relativa gli esempi..." type="textarea" className=""></Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={3}>
                                            <Row className="mt-5">
                                                <Col md={12}>
                                                    <h4>Ogni quanto scade?</h4>
                                                    <p>Indica la scadenza <b>IN MESI</b> del documento, ciò ci permette di notificarti
                                                        le prossime scadenze automaticamente.
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={8}>
                                                    <FormGroup>
                                                        {/* <Label>Scadenza (in mesi)</Label> */}
                                                        <Input onChange={this.handleChange} name="expiration" placeholder="..." type="number" className="bigger-input"></Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row className="justify-content-center">
                                    <Col md={12}>
                                        <Button className="btn-shadow mr-4 bigger" onClick={this.resetState} color="danger">Pulisci i campi</Button>
                                        <ButtonLoading onClick={this.submitForm} className="btn-shadow bigger" loading={isPending} color="primary">Salva il modello</ButtonLoading>
                                    </Col>
                                </Row>
                            </div>
                        )}


                    </Col>
                </Row>


                <Modal isOpen={modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Aggiungi un nuovo tipo di documento</ModalHeader>
                    <ModalBody>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggle}>Do Something</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>

                <Row className="justify-content-start">

                </Row>
            </Container >
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getTypes: () => dispatch(getTypesAction()),
    submitForm: documentModel => dispatch(addDocumentModelAction(documentModel)),
    cleanUpNewDocumentModel: () => dispatch(cleanUpNewDocumentModelAction())
})

const mapStateToProps = state => {

    const types = state.documents.types;
    const isPending = state.documents.documentModels.pending;
    const isCreated = state.documents.documentModels.created;
    const error = state.documents.documentModels.error;
    let errorUnserialized = false;
    if (error) {
        errorUnserialized = JSON.parse(error.serialized)
    }
    return { types, isPending, isCreated, error, errorUnserialized };
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentModelAdd);