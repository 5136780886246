import { createReducer } from '@reduxjs/toolkit'
import { merge, groupBy } from 'lodash'

const initialState = {
    byId: {},
    allIds: [],
    created: {
        status: false,
        emplyoee: null
    },
    edited: {
        status: false,
        employee: null,
        message: null
    },
    pending: false,
    error: null
}

const employeeReducer = createReducer(initialState, {

    ADD_EMPLOYEE_PENDING: (state, action) => {
        state.pending = true;
    },
    ADD_EMPLOYEE_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false

        if (payload.entities && payload.entities.employees) {
            state.byId = merge({}, state.byId, payload.entities.employees)
            state.allIds = merge([], state.allIds, Object.keys(payload.entities.employees))
            state.created.status = true;
            state.created.employee = Object.values(payload.entities.employees)
        }
        return state
    },
    ADD_EMPLOYEE_ERROR: (state, action) => {
        state.pending = false
        return state
    },

    GET_COMPANY_EMPLOYEES_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false

        if (payload.entities && payload.entities.employees) {
            state.byId = merge({}, state.byId, payload.entities.employees)
            state.allIds = merge([], state.allIds, Object.keys(payload.entities.employees))
        }
        return state
    },
    GET_EMPLOYEES_PENDING: (state, action) => {
        state.pending = true
    },

    GET_EMPLOYEES_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false

        if (payload.entities && payload.entities.employees) {
            state.byId = merge({}, state.byId, payload.entities.employees)
            state.allIds = merge([], state.allIds, Object.keys(payload.entities.employees))
        }
        return state
    },
    GET_EMPLOYEES_ERROR: (state, action) => {
        state.error = action.payload;
        state.pending = false
    },
    GET_DOCUMENTS_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false


        if (payload.entities && payload.entities.documents) {
            const { payload: { entities: { documents } } } = action
            const docGroupByEmployee = groupBy(documents, doc => doc.employee_id)
            for (let employeeId of Object.keys(docGroupByEmployee)) {
                if (state.byId[employeeId]) {
                    state.byId[employeeId].documents = docGroupByEmployee[employeeId].map(document => document.id)

                }
            }
        }
        return state
    },
    GET_EMPLOYEEDOCUMENTS_PENDING: (state, action) => {
        const { payload } = action
        state.pending = true

    },
    GET_EMPLOYEEDOCUMENTS_ERROR: (state, action) => {
        const { payload } = action
        state.pending = false
    },
    GET_EMPLOYEEDOCUMENTS_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false


        if (payload.entities && payload.entities.documents) {
            const { payload: { entities: { documents } } } = action
            const docGroupByEmployee = groupBy(documents, doc => doc.employee)
            for (let employeeId of Object.keys(docGroupByEmployee)) {
                if (state.byId[employeeId]) {
                    state.byId[employeeId].documents = docGroupByEmployee[employeeId].map(document => document.id)

                }
            }
        }
        return state
    },

    DELETE_EMPLOYEE_PENDING: (state, action) => {
        state.pending = true;
    },
    DELETE_EMPLOYEE_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false

        if (isNaN(payload)) return state

        const employeeId = payload
        if (state.byId[employeeId]) {
            const newAllIds = state.allIds
            const filteredIds = newAllIds.filter(value => Number(value) !== employeeId)

            state.allIds = filteredIds
            delete state.byId[employeeId]
        }

    },
    DELETE_EMPLOYEE_ERROR: (state, action) => {
        state.pending = false
        return state
    },
    CLEAN_NEW_EMPLOYEE: (state, action) => {
        state.created.status = false
        state.created.employee = null
    },
    EDIT_EMPLOYEE_PENDING: (state, action) => {
        state.pending = true;
    },
    EDIT_EMPLOYEE_SUCCESS: (state, action) => {
        const { payload } = action
        state.pending = false;
        if (payload.entities && payload.entities.employees) {
            state.byId = merge({}, state.byId, payload.entities.employees)
            state.allIds = merge([], state.allIds, Object.keys(payload.entities.employees))
            state.edited.status = true;
            state.edited.employee = payload.entities.employees
            state.edited.message = "Tutto ok, abbiamo aggiornato il dipendente!"
        }
        return state
    },
    EDIT_EMPLOYEE_ERROR: (state, action) => {
        state.pending = false;
    },
})

export default employeeReducer