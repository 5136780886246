import Api from '../api/api'
import { createAction } from '@reduxjs/toolkit'
import { company, employee } from '../config/schemas/'
import { normalize, schema } from 'normalizr'

export const addCompanyAction = newCompany => {
    return async dispatch => {
        dispatch(addCompanyPending())
        const response = await Api.addCompany(newCompany)
        if (response.message) {
            dispatch(addCompanyError(response))
        }
        else {
            dispatch(addCompanySuccess(normalize(response.data, company)))
        }
    }
}

export const editCompanyAction = modifiedCompany => {
    return async dispatch => {
        dispatch(editCompanyPending())
        const response = await Api.editCompany(modifiedCompany)
        if (response.message) {
            dispatch(editCompanyError(response))
        }
        else {
            dispatch(editCompanySuccess(normalize(response.data, company)))
        }
    }
}

export const deleteCompanyAction = companyId => {
    return async dispatch => {
        dispatch(deleteCompanyPending())
        const response = await Api.deleteCompany(companyId)
        if (response.message) {
            dispatch(deleteCompanyError(response))
        }
        else {
            dispatch(deleteCompanySuccess(companyId))
        }
    }
}

export const getCompaniesAction = companyId => {
    return async dispatch => {
        dispatch(getCompaniesPending())
        const response = await Api.getCompanies(companyId)
        if (response.message) {
            dispatch(getCompaniesError(response))
        }
        else {
            if (Array.isArray(response.data)) {
                dispatch(getCompaniesSuccess(normalize(response.data, [company])))
            } else {
                dispatch(getCompaniesSuccess(normalize(response.data, company)))
            }
        }
    }
}

export const getCompanyEmployeesAction = companyId => {
    return async dispatch => {
        dispatch(getCompanyEmployeesPending())
        const response = await Api.getCompanyEmployees(companyId)
        if (response.message) {
            dispatch(getCompanyEmployeesError(response))
        }
        else {
            const data = response.data;
            console.log(response.data, normalize(data, company))
            dispatch(getCompanyEmployeesSuccess(normalize(data, company)))

        }
    }
}

export const getCompanyDocumentsAction = companyId => {
    return async dispatch => {
        dispatch(getCompanyDocumentsPending())
        const response = await Api.getCompanyDocuments(companyId)
        if (response.message) {
            dispatch(getCompanyDocumentsError(response))
        }
        else {
            const data = response.data;
            console.log(normalize(data, company))
            dispatch(getCompanyDocumentsSuccess(normalize(data, company)))
        }
    }
}

export const addCompanyPending = createAction("ADD_COMPANY_PENDING")
export const addCompanyError = createAction("ADD_COMPANY_ERROR")
export const addCompanySuccess = createAction("ADD_COMPANY_SUCCESS")

export const editCompanyPending = createAction("EDIT_COMPANY_PENDING")
export const editCompanyError = createAction("EDIT_COMPANY_ERROR")
export const editCompanySuccess = createAction("EDIT_COMPANY_SUCCESS")

export const deleteCompanyPending = createAction("DELETE_COMPANY_PENDING")
export const deleteCompanyError = createAction("DELETE_COMPANY_ERROR")
export const deleteCompanySuccess = createAction("DELETE_COMPANY_SUCCESS")

export const getCompaniesPending = createAction("GET_COMPANIES_PENDING")
export const getCompaniesError = createAction("GET_COMPANIES_ERROR")
export const getCompaniesSuccess = createAction("GET_COMPANIES_SUCCESS")

export const getCompanyEmployeesPending = createAction("GET_COMPANY_EMPLOYEES_PENDING")
export const getCompanyEmployeesError = createAction("GET_COMPANY_EMPLOYEES_ERROR")
export const getCompanyEmployeesSuccess = createAction("GET_COMPANY_EMPLOYEES_SUCCESS")

export const getCompanyDocumentsPending = createAction("GET_COMPANY_DOCUMENTS_PENDING")
export const getCompanyDocumentsError = createAction("GET_COMPANY_DOCUMENTS_ERROR")
export const getCompanyDocumentsSuccess = createAction("GET_COMPANY_DOCUMENTS_SUCCESS")

export const cleanUpNewCompanyAction = createAction("CLEAN_NEW_COMPANY")
export const cleanUpEditCompanyAction = createAction("CLEAN_EDIT_COMPANY")