import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom'
import Api from '../../../api/api'
import {
    PageTitle,
    DataTable,
    ErrorCard,
    FormInfo,
    ActionTooltip
} from '../../../components/'
import {
    Button, FormGroup,
    Row, Col,
    Label,
    CardFooter, Container,
    ListGroupItem, ListGroup
} from 'reactstrap';
import Select from 'react-select'
import { FaPlus, FaDownload } from 'react-icons/fa'
import { FiTrash2 } from 'react-icons/fi'
import {
    getCompaniesAction,
    getCompanyEmployeesAction,
    getCompanyDocumentsAction,
    deleteCompanyAction,
    editCompanyAction,
    cleanUpEditCompanyAction
} from '../../../actions/companyAction'
import {
    getDocumentModelsAction,
    getTypesAction,
    deleteDocumentAction,
} from '../../../actions/documentAction'
import {
    deleteEmployeeAction
} from '../../../actions/employeesAction'
import { parseForOptions } from '../../../utils/select/transformIntoOption'
import { labelMap, schema } from '../../../utils/forms/company'
import { CSVLink, CSVDownload } from "react-csv";
import { PDFDownloadLink } from '@react-pdf/renderer'
import PdfDocument from './pdfDocument'


const RapidAction = ({ company, handleDelete, children }) => (
    <Col md="4" className="pr-5 d-flex flex-column justify-content-start align-items-end">
        <h4 className="mt-2 mb-3 mr-2 text-light">
            Azioni rapide
        </h4>
        <Link to={{ pathname: '/document/add/', state: { companyId: company.id } }}>
            <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
                <div className="mr-3">
                    <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
                </div>
                <div className="text-black">
                    Inserisci nuova scadenza
                </div>
            </Button>
        </Link>
        <Link to={{ pathname: '/employee/add/', state: { companyId: company.id } }}>
            <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
                <div className="mr-3">
                    <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
                </div>
                <div className="text-black">
                    Aggiungi un dipendente
                </div>
            </Button>
        </Link>
        {children}
        {handleDelete &&
            <>
                <ActionTooltip
                    id={`item-delete`}
                    handleAction={() => handleDelete(company.id)}
                    item={{ placement: "bottom" }}
                    text={"Sei sicuro di voler eliminare questo oggetto?"}
                    color="white"
                />
                <Button id={`Tooltip-item-delete`} className="new-expiration-button py-1 pl-1 pr-3 shadow mb-3 d-flex text-left align-items-center">
                    <div className="mr-3">
                        <FiTrash2 className="hoverable-icon static danger p-2"></FiTrash2>
                    </div>
                    <div className="text-danger">
                        Cancella l'azienda #{company.id}
                    </div>
                </Button>
            </>

        }

    </Col>
)


class CompanyEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            company: {
                id: 0,
                shorthand: '',
                business_name: '',
                description: '',
                sector_id: '',
                telephone_number: '',
                referrer_id: '',
                vat_number: "",
                address: '',
                expiries: 0,
                createdAt: '',
            },
            select: {
                sector_id: {
                    value: null,
                    label: null
                },
                referrer_id: {
                    value: null,
                    label: null
                }
            },
            employees: [],
            documents: [],
            sectors: [],
            employeesOption: [],
            csvData: []
        };
    }

    get companyEmployees() {

        const allEmployees = Object.values(this.props.employees)
        const companyId = this.props.match.params.id
        const company = this.props.companies.byId[companyId];
        if (!company) return []

        if (company.employees) {
            return allEmployees.filter((employee) => company.employees.includes(employee.id))
        }
        return []
    }

    get companyDocuments() {
        const allDocuments = Object.values(this.props.documents)
        const companyId = this.props.match.params.id
        const company = this.props.companies.byId[companyId];
        if (!company) return []

        if (company.documents) {
            return allDocuments.filter((document) => company.documents.includes(document.id))
        }
        return []
    }

    get companyDocumentsSorted() {
        const allDocuments = Object.values(this.props.documents)
        const companyId = this.props.match.params.id
        const company = this.props.companies.byId[companyId];
        if (!company) return []

        if (company.documents) {
            return allDocuments
                .filter((document) => company.documents.includes(document.id))
                .sort((a, b) => new Date(a.expiration_date) - new Date(b.expiration_date))
        }
        return []
    }

    get exportCompanyDocuments() {
        const filteredDocuments = this.companyDocumentsSorted
        const documentModels = this.props.documentModels.byId
        const employees = this.props.employees

        const companyId = this.props.match.params.id
        const company = this.props.companies.byId[companyId];

        const data = filteredDocuments.map(document => {
            const documentModel = documentModels[document.documentmodel_id]
            const employee = employees[document.employee_id] || false

            return {
                ...document,
                documentmodel_id: documentModel.name,
                company_id: company.business_name,
                employee_id: employee && `${employee.first_name} ${employee.last_name}`
            }
        })

        return data;
    }

    handleChange = evt => {
        console.log(evt.formData, evt.formData || '')
        this.setState(prevState => ({
            ...prevState,
            company: {
                ...prevState.company,
                ...evt.formData || '',
            }
        }), () => console.log("company/edit: updating state", this.state))
    }

    handleSelect = (evt, metadata) => {
        const valueForSelect = evt;
        const value = evt.value;
        const name = metadata.name;
        console.log(valueForSelect, value, name)
        this.setState(prevState => ({
            ...prevState,
            company: {
                ...prevState.company,
                [name]: value
            },
            select: {
                ...prevState.select,
                [name]: valueForSelect
            }
        }), () => console.log("company/edit: updating state", this.state))
    }

    handleDelete = async id => {
        await this.props.deleteCompany(id)
        this.props.history.push('/company/')
    }

    handleDeleteEmployee = employeeId => {
        this.props.deleteEmployee(employeeId)
    }

    handleDeleteDocument = documentId => {
        this.props.deleteDocument(documentId)
    }

    handleSubmit = () => {
        console.log(this.state.company)
        this.props.editCompany(this.state.company)
    }

    loadSectors = async () => {
        const sectors = await Api.getSectors()
        return sectors
    }

    componentWillUnmount() {
        this.props.cleanUpEditCompany()
    }

    renderCell = (field, value) => {
        switch (field) {
            case "id":
                return (
                    <td className="text-center text-muted p-3" name={field}>
                        {value}
                    </td>
                );
            case "documentsId":
                return (
                    <td className="text-center p-3">
                        <div className={"badge badge-pill badge-info"}>{value.length}</div>
                    </td>
                );
            case "documentmodel_id":
                return (
                    <td className="text-center p-3" name={field}>
                        {this.props.documentModels.byId[value] && this.props.documentModels.byId[value].name}
                    </td>
                )
            default:
                return (
                    <td className="text-center p-3" name={field}>
                        {value}
                    </td>
                );
        }
    };


    async componentDidMount() {
        if (!this.props.match.params.id) return false;
        const companyId = Number(this.props.match.params.id)
        if (!this.props.companies.byId[companyId]) {
            await this.props.getCompanies(companyId)
            if (!this.props.companies.byId[companyId]) {
                return this.props.history.push('/company/')
            }
        }

        const company = this.props.companies.byId[companyId]
        this.setState({ company: company })


        const sectors = await this.loadSectors()
        const sectorsOption = parseForOptions(sectors.data, 'id', 'name', 'description')
        this.setState({ sectors: sectorsOption })

        if (sectors.data && company.sector_id && company.sector_id !== "0") {
            const sector = sectorsOption.filter(sector => Number(sector.value) === Number(company.sector_id))
            const selectSector = {
                value: company.sector_id,
                label: sector[0].label
            }
            this.setState(prevState => ({
                ...prevState,
                select: {
                    ...prevState.select,
                    sector_id: selectSector
                }
            }))
        }

        await this.props.getDocumentModels();
        await this.props.getTypes()
        await this.props.getCompanyDocuments(companyId)
        await this.props.getCompanyEmployees(companyId)

        const employees = this.companyEmployees
        const employeesOption = parseForOptions(employees, 'id', 'first_name', 'last_name')
        this.setState({ employeesOption })

        // if (employees.length > 0 && company.referrer_id) {
        //     const employeeRef = employees.filter(employee => Number(employee.id) === Number(company.referrer_id))
        //     const selectReferrer = {
        //         value: employeeRef[0].id,
        //         label: `${employeeRef[0].first_name} ${employeeRef[0].last_name}`
        //     }
        //     this.setState(prevState => ({
        //         ...prevState,
        //         select: {
        //             ...prevState.select,
        //             referrer_id: selectReferrer
        //         }
        //     }), () => console.log(this.state, employeeRef, selectReferrer))
        // }
    }



    render() {
        const { companies, employees, documents, pending, edited, documentModels, types, user, match: { params: { id } } } = this.props;
        const company = companies.byId[id];


        if (!company) {
            return <h1>Please wait...</h1>
        }

        return (
            <Container fluid>
                {
                    company &&
                    <PageTitle
                        heading={company.business_name}
                        subheading={`L'utente ${user.username} ha creato l'azienda il ${company.created_at.slice(0, 10)} con l'alias ${company.name}`}
                        icon={null}
                        color={true}
                        documents={this.companyDocuments.length}
                        employees={this.companyEmployees.length}
                    >
                        <RapidAction
                            handleDelete={this.handleDelete}
                            company={company}
                        >
                            {!this.state.pdfReady ?
                                (
                                    <Button
                                        onClick={() => this.setState({ 'pdfReady': true })}
                                        className="new-expiration-button py-1 pl-1 pr-3 shadow mb-4 d-flex text-left align-items-center"
                                    >
                                        <div className="mr-3">
                                            <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
                                        </div>
                                        <div className="text-black">
                                            Genera pdf
                                    </div>
                                    </Button>
                                ) :
                                (
                                    <Button
                                        onClick={() => this.setState({ 'pdfReady': true })}
                                        className="new-expiration-button py-1 pl-1 pr-3 shadow mb-4 d-flex text-left align-items-center"
                                    >
                                        <div className="mr-3">
                                            <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
                                        </div>
                                        <div className="text-black">
                                            <PDFDownloadLink
                                                document={
                                                    <PdfDocument
                                                        company={company}
                                                        employees={this.companyEmployees}
                                                        documents={this.exportCompanyDocuments}
                                                    />
                                                }
                                                fileName="test.pdf"
                                            >
                                                {({ blob, url, loading, error }) => {
                                                    console.log(blob)
                                                    return (loading ? 'Stiamo generando il documento...' : 'Pronto! Scaricalo ora!')
                                                }}
                                            </PDFDownloadLink>
                                        </div>
                                    </Button>
                                )
                            }
                        </RapidAction>
                    </PageTitle>

                }
                <Row className="my-5 pt-5">
                    <Col md="6">
                        <h2 className="mb-3">Anagrafica</h2>

                        {/* This is only an hackettino perchè react-pdf c'ha la mamma troia */}

                        <FormInfo
                            schema={schema}
                            formData={this.state.company}
                            pending={pending}
                            onChange={e => this.handleChange(e)}
                            onSubmit={() => this.handleSubmit()}
                            onError={e => console.log(e)}
                            isChildrenInside={true}
                            messages={edited.status !== null && {
                                status: edited.status,
                                message: edited.message
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    Settore
                                </Label>
                                <Select
                                    isLoading={this.state.sectors.length === 0}
                                    name="sector_id"
                                    options={this.state.sectors}
                                    onChange={this.handleSelect}
                                    value={this.state.select.sector_id}
                                />
                                {/* <Label className="mt-3">
                                    Referral
                                </Label> */}
                                {/* <Select
                                    isLoading={this.state.employeesOption.length === 0}
                                    name="referrer_id"
                                    options={this.state.employeesOption}
                                    onChange={this.handleSelect}
                                    value={this.state.select.referrer_id}
                                /> */}
                            </FormGroup>
                        </FormInfo>
                    </Col>
                    <Col md="6 smaller-img">
                        <h2 className="mb-3">Referente</h2>
                        {
                            (company && company.referrer_id) ?
                                (<ErrorCard
                                    customText={`Il referente dell'azienda è ${company.referrer_id}`}
                                    translation={null}
                                    bread={'Dipendenti'}
                                >
                                </ErrorCard>)
                                :
                                (
                                    <ErrorCard
                                        customText={`Non è ancora stato assegnato un referente all'azienda`}
                                        translation={null}
                                        bread={'Dipendenti'}
                                    />
                                )
                        }

                    </Col>
                </Row>
                <Row>
                    <Col md="12 mb-5">
                        <div className="d-flex flex-row justify-content-between">
                            <h2 className="mb-3">Documenti</h2>
                            <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
                                <div className="mr-3">
                                    <FaDownload className="hoverable-icon static brand p-2"></FaDownload>
                                </div>
                                <div className="text-black">
                                    <CSVLink data={this.exportCompanyDocuments}>Esporta lista documenti in .csv</CSVLink>
                                </div>
                            </Button>
                        </div>
                        {this.companyDocuments.length > 0 ?
                            (
                                <>
                                    <DataTable
                                        title="Cerca:"
                                        handleDelete={this.handleDeleteDocument}
                                        columns={[
                                            {
                                                Header: 'Id',
                                                accessor: 'id'
                                            },
                                            {
                                                Header: 'Nome',
                                                accessor: 'documentmodel_id'
                                            },
                                            {
                                                Header: 'Scadenza',
                                                accessor: 'expiration_date'
                                            },
                                            {
                                                Header: 'Assegnato a',
                                                accessor: row => (
                                                    employees[row.employee_id] &&
                                                    `${employees[row.employee_id].first_name} ${employees[row.employee_id].last_name}` ||
                                                    "Nessun dipendente"
                                                )
                                            },
                                        ]}
                                        data={this.companyDocuments}
                                        model={"document"}
                                        linkData={{ state: { companyId: company.id } }}
                                        renderCell={this.renderCell}
                                    />
                                </>
                            ) :
                            <ErrorCard
                                img={false}
                                model={company}
                                translation={'documenti'}
                                action={company && { pathname: '/document/add', state: { companyId: company.id }, cta: 'Aggiungi un documento' }}
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <div className="d-flex flex-row justify-content-between">
                            <h2 className="mb-3">Dipendenti</h2>
                            <Button className="new-expiration-button py-1 pl-1 pr-3 shadow mb-2 d-flex text-left align-items-center">
                                <div className="mr-3">
                                    <FaDownload className="hoverable-icon static brand p-2"></FaDownload>
                                </div>
                                <div className="text-black">
                                    <CSVLink data={this.companyEmployees}>Esporta lista dipendenti in .csv</CSVLink>
                                </div>
                            </Button>
                        </div>
                        {this.companyEmployees.length > 0 ?
                            (
                                <>
                                    <DataTable
                                        title="Cerca:"
                                        handleDelete={this.handleDeleteEmployee}
                                        columns={[
                                            {
                                                Header: 'Id',
                                                accessor: 'id'
                                            },
                                            {
                                                Header: 'Nome',
                                                accessor: 'first_name'
                                            },
                                            {
                                                Header: 'Cognome',
                                                accessor: 'last_name'
                                            }
                                        ]}
                                        data={this.companyEmployees}
                                        model={"employee"}
                                        renderCell={this.renderCell}
                                    />
                                </>
                            )
                            :
                            <ErrorCard
                                img={false}
                                model={company}
                                translation={'dipendenti'}
                                action={company && { pathname: '/employee/add', state: { companyId: company.id }, cta: 'Aggiungi un dipendente' }}
                            />
                        }
                    </Col>
                </Row>

            </Container >
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getCompanies: companyId => dispatch(getCompaniesAction(companyId)),
    getCompanyEmployees: companyId => dispatch(getCompanyEmployeesAction(companyId)),
    getCompanyDocuments: companyId => dispatch(getCompanyDocumentsAction(companyId)),
    getDocumentModels: () => dispatch(getDocumentModelsAction()),
    getTypes: () => dispatch(getTypesAction()),
    deleteCompany: companyId => dispatch(deleteCompanyAction(companyId)),
    deleteEmployee: employeeId => dispatch(deleteEmployeeAction(employeeId)),
    deleteDocument: documentId => dispatch(deleteDocumentAction(documentId)),
    editCompany: company => dispatch(editCompanyAction(company)),
    cleanUpEditCompany: () => dispatch(cleanUpEditCompanyAction())
})

const mapStateToProps = state => {
    const companies = state.companies
    const employees = state.employees.byId
    const documents = state.documents.documents.byId
    const documentModels = state.documents.documentModels
    const types = state.documents.types
    const user = state.user.user
    const edited = state.companies.edited
    const pending = state.companies.pending

    return { companies, employees, documents, documentModels, user, types, pending, edited }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CompanyEdit))