import React from "react"
import styled from "styled-components"
import {
  Heading,
  HeadingLink,
  LinkList,
  DropdownSection,
  Icon
} from "./Components"
import { HomeNav, ScadenzeNav, ObjectsNav, ModelNav } from '../../../layout/AppNav/NavItems';
import RouterLink from 'react-metismenu-router-link';
import MetisMenu from 'react-metismenu';

const CompanyDropdownEl = styled.div`
  width: 18.5rem;
`

const CompanyDropdown = () => {
  return (
    <CompanyDropdownEl>
      <DropdownSection data-first-dropdown-section>
      </DropdownSection>
    </CompanyDropdownEl>
  )
}

export default CompanyDropdown
