import Api from '../api/api'
import { createAction } from '@reduxjs/toolkit'
import { company, employee } from '../config/schemas/'
import { normalize, schema } from 'normalizr'

export const addEmployeeAction = newEmployee => {
    return async dispatch => {
        dispatch(addEmployeePending())
        const response = await Api.addEmployee(newEmployee)
        if (response.message) {
            dispatch(addEmployeeError(response))
        }
        else {
            dispatch(addEmployeeSuccess(normalize(response.data, employee)))
        }
    }
}

export const editEmployeeAction = modifiedEmployee => {
    return async dispatch => {
        dispatch(editEmployeePending())
        const response = await Api.editEmployee(modifiedEmployee)
        if (response.message) {
            dispatch(editEmployeeError(response))
        }
        else {
            dispatch(editEmployeeSuccess(normalize(response.data, employee)))
        }
    }
}

export const deleteEmployeeAction = employeeId => {
    return async dispatch => {
        dispatch(deleteEmployeePending())
        const response = await Api.deleteEmployee(employeeId)
        if (response.message) {
            dispatch(deleteEmployeeError(response))
        }
        else {
            dispatch(deleteEmployeeSuccess(employeeId))
        }
    }
}

export const getEmployeesAction = employeeId => {
    return async dispatch => {
        dispatch(getEmployeesPending())
        const response = await Api.getEmployees(employeeId)
        if (response.message) {
            dispatch(getEmployeesError(response))
        }
        else {
            const data = response.data;
            dispatch(getEmployeesSuccess(normalize(data, [employee])))
        }
    }
}

export const getEmployeeDocumentsAction = employeeId => {

    return async dispatch => {
        dispatch(getEmployeeDocumentsPending())
        const response = await Api.getEmployeeDocuments(employeeId)
        if (response.message) {
            dispatch(getEmployeeDocumentsError(response))
        }
        else {
            const data = response.data;
            dispatch(getEmployeeDocumentsSuccess(normalize(data, employee)))
        }
    }
}

export const addEmployeePending = createAction("ADD_EMPLOYEE_PENDING")
export const addEmployeeError = createAction("ADD_EMPLOYEE_ERROR")
export const addEmployeeSuccess = createAction("ADD_EMPLOYEE_SUCCESS")

export const editEmployeePending = createAction("EDIT_EMPLOYEE_PENDING")
export const editEmployeeError = createAction("EDIT_EMPLOYEE_ERROR")
export const editEmployeeSuccess = createAction("EDIT_EMPLOYEE_SUCCESS")

export const getEmployeesPending = createAction("GET_EMPLOYEES_PENDING")
export const getEmployeesError = createAction("GET_EMPLOYEES_ERROR")
export const getEmployeesSuccess = createAction("GET_EMPLOYEES_SUCCESS")

export const deleteEmployeePending = createAction("DELETE_EMPLOYEE_PENDING")
export const deleteEmployeeError = createAction("DELETE_EMPLOYEE_ERROR")
export const deleteEmployeeSuccess = createAction("DELETE_EMPLOYEE_SUCCESS")

export const getEmployeeDocumentsPending = createAction("GET_EMPLOYEEDOCUMENTS_PENDING")
export const getEmployeeDocumentsSuccess = createAction("GET_EMPLOYEEDOCUMENTS_SUCCESS")
export const getEmployeeDocumentsError = createAction("GET_EMPLOYEEDOCUMENTS_ERROR")

export const cleanUpNewEmployeeAction = createAction("CLEAN_NEW_EMPLOYEE")