import React, { Fragment } from 'react';
import cx from 'classnames';
import Menu from '../../components/react-stripe-menu'
import { connect } from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import HeaderLogo from '../AppLogo';
import UserBox from './Components/UserBox';

class Header extends React.Component {
    render() {
        let {
            headerBackgroundColor,
            enableMobileMenuSmall,
            enableHeaderShadow,
            auth
        } = this.props;
        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    className={cx("app-header pl-md-5 py-md-5 mb-md-3 py-3 d-flex justify-space-between", headerBackgroundColor, { 'header-shadow': enableHeaderShadow })}
                    transitionName="HeaderAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}>

                    <HeaderLogo />
                    <Menu
                        duration={300}
                        onChange={this.onChange}
                    />
                    <div className={cx(
                        "app-header__content padding-right__search",
                        { 'header-mobile-open': enableMobileMenuSmall },
                    )}>

                        {/* <div className="app-header-left">
                            <FilterInput />
                        </div> */}

                        <div className="app-header-right">
                            <UserBox auth={auth} />
                        </div>
                    </div>
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
    auth: state.user.user
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
