import React from "react"
import styled from "styled-components"
import {
  Icon,
  DropdownSection,
  Heading,
  HeadingLink,
  LinkList
} from "./Components"
import { Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa'

const DevelopersDropdownEl = styled.div`
  width: 25rem;
`

const Flex = styled.div`
  display: flex;
  > div:first-of-type {
    margin-right: 48px;
  }
`

const DevelopersDropdown = () => {
  return (
    <DevelopersDropdownEl>
      <DropdownSection data-first-dropdown-section>
        {/* <Heading>Documentation</Heading> */}
        <p className="menu-link font-size-md">Visualizza la lista dei documenti o utilizza le funzioni di aggiunta rapida.</p>
        <div className="pt-3">
          <Link to="/document/add/">
            <Button className="new-expiration-button d-flex text-left align-items-center">
              <div className="mr-3">
                <FaPlus className="hoverable-icon static brand p-2"></FaPlus>
              </div>
              <div className="menu-link">
                Inserisci nuovo <b>documento</b>
              </div>
            </Button>
          </Link>
        </div>
        <div className="pt-3">
          <HeadingLink>
            <Link to="/document/" className="menu-link">
              Tutti i documenti
          </Link>
          </HeadingLink>
          <HeadingLink>
            <Link to="/documentModel/" className="menu-link">
              Tutti i modelli di documento
          </Link>
          </HeadingLink>
        </div>

      </DropdownSection>
    </DevelopersDropdownEl>
  )
}

export default withRouter(DevelopersDropdown)
