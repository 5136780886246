const schema = {
    title: "",
    type: "object",
    required: [],
    properties: {
        id: { type: "number", title: "Id", default: "", readOnly: true },
        first_name: { type: "string", title: "Nome", },
        last_name: { type: "string", title: "Cognome", },
        email: { type: "string", title: "Email", },
        telephone_number: { type: "string", title: "Telefono", },
        created_at: { type: "string", title: "Creato il", readOnly: true },

    }
};

export { schema };