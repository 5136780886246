import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { FaEye, FaPlus } from 'react-icons/fa'
import {
    PageTitle,
    FormInfo,
} from '../../../components/'
import Select from 'react-select'
import {
    Button, Container,
    Row, Col,
} from 'reactstrap';
import {
    getCompaniesAction,
} from '../../../actions/companyAction'
import {
    addEmployeeAction,
    cleanUpNewEmployeeAction
} from '../../../actions/employeesAction'
import { schema } from '../../../utils/forms/employee'
import { parseForOptions, formatDate } from '../../../utils/select/transformIntoOption'
import { confirmImg } from '../../../assets/images'

class EmployeeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            last_name: "",
            company_id: null,
            select: {
                company_id: null
            }
        }

        this.initialState = this.state

    }


    handleChange = evt => {
        this.setState(prevState => ({
            ...prevState,
            ...evt.formData,

        }), () => console.log("employee/add: updating state", this.state))
    }

    handleSelect = (evt, metadata) => {
        const valueForSelect = evt;
        const value = evt.value;
        const name = metadata.name;
        console.log(valueForSelect, value, name)
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
            select: {
                ...prevState.select,
                [name]: valueForSelect
            }
        }), () => console.log("employee/add: updating state", this.state))
    }


    submitForm = evt => {
        this.props.addEmployee(this.state)
    }

    async componentDidMount() {
        await this.props.getCompanies();
        if (this.props.location.state && this.props.location.state.companyId) {
            const { companiesOption, location: { state: { companyId } } } = this.props;
            const selectedCompany = companiesOption.filter(company => company.value === companyId)
            this.setState(prevState => ({
                ...prevState,
                company_id: companyId,
                select: {
                    ...prevState.select,
                    company_id: selectedCompany
                }
            })
            )
        }
    }

    componentWillUnmount() {
        this.props.cleanUpNewEmployee()
    }

    resetState = () => {
        this.setState(this.initialState);
        this.props.cleanUpNewEmployee();
    }

    render() {

        delete schema.properties.id;
        delete schema.properties.created_at;

        const { companies, companiesOption, isPending, created } = this.props;
        const { select } = this.state;

        return (
            <>
                <PageTitle
                    heading="Aggiungi un nuovo dipendente"
                    subheading="Qui puoi creare un dipendente ed aggiungerlo ad un'azienda"
                    icon={false}
                    color={false}
                >
                </PageTitle>
                <Container fluid>
                    <Row>
                        <Col md="6 px-0">
                            <FormInfo
                                heading={"Inserisci i dati anagrafici"}
                                pending={isPending}
                                schema={schema}
                                onChange={e => this.handleChange(e)}
                                onSubmit={e => this.submitForm(e)}
                                onError={e => console.log(e)}
                                formData={this.state}
                                isChildrenInside={true}

                            >
                                <h4>Seleziona l'azienda a cui vuoi assegnarlo</h4>
                                <Select
                                    value={select.company_id}
                                    onChange={this.handleSelect}
                                    name="company_id"
                                    className="bigger"
                                    options={companiesOption}>
                                </Select>
                            </FormInfo>
                        </Col>
                        {created.status && <Col md="6" className="d-flex flex-column justify-content-center align-items-center">
                            <h3 className="">Inserita con successo!</h3>
                            <h6 className="lead mb-4">Abbiamo aggiunto {created.employee[0].first_name} {created.employee[0].last_name} al sistema</h6>
                            <img width="30%" src={confirmImg}></img>
                            <Row className="justify-content-center mt-4">
                                <Col md={12}>
                                    <Button className="btn-shadow mr-4 bigger" onClick={this.resetState} color="info">Inserisci un altro dipendente</Button>
                                    <Link to={`/employee/edit/${created.employee[0].id}`}>
                                        <Button className="btn-shadow bigger" color="primary">Vai al dipendente</Button>
                                    </Link>
                                </Col>
                            </Row>
                            <Row className="justify-content-center mt-4">
                                <Col md={12}>
                                    <Link
                                        to={
                                            {
                                                pathname: '/document/add/',
                                                state: {
                                                    companyId: created.employee[0].company_id,
                                                    employeeId: created.employee[0].id
                                                }
                                            }}>
                                        <Button className="btn-shadow bigger" color="warning">Assegna subito una scadenza</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Col>}
                    </Row>
                </Container>
            </>
        )
    }
}


const mapDispatchToProps = dispatch => ({
    getCompanies: () => dispatch(getCompaniesAction()),
    addEmployee: employee => dispatch(addEmployeeAction(employee)),
    cleanUpNewEmployee: () => dispatch(cleanUpNewEmployeeAction())
})

const mapStateToProps = state => {
    const companies = state.companies
    const companiesOption = parseForOptions(Object.values(companies.byId), 'id', 'business_name');
    const isPending = state.employees.pending;
    const created = state.employees.created;

    return { companies, companiesOption, isPending, created }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmployeeAdd))
