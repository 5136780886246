import { combineReducers } from 'redux'
import ThemeOptions from './ThemeOptions';
import User from './Authentication'
import Document from './Document'
import Employee from './Employee'
import Company from './Company'

export default combineReducers({
    user: User,
    companies: Company,
    employees: Employee,
    documents: Document,
    ThemeOptions
});
